import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Step } from '../models/step';
import { BaseAPIService } from './base-api.service';
import { environment } from '../environments/environment';
import { TestCase, TestCaseAlternative } from 'src/models/testCase';

@Injectable({
  providedIn: 'root'
})
export class TestCaseService extends BaseAPIService {

  constructor(private http: HttpClient) {
    super();
   }

  getTestCase(testCaseUid: string, testCaseVersion: number): Observable<TestCase> {
    const headers = new HttpHeaders()
    .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<TestCase>(`${environment.baseurl}testcase/${testCaseUid}/${testCaseVersion}`, {withCredentials: true, headers})
    .pipe(catchError(this.handleError));
  }

  getLatestTestCase(testCaseUid: string): Observable<TestCase> {
    const headers = new HttpHeaders()
    .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<TestCase>(`${environment.baseurl}testcase/${testCaseUid}`, {withCredentials: true, headers})
    .pipe(catchError(this.handleError));
  }

  getTestCaseSteps(testCaseUid: string, testCaseVersion: number): Observable<Array<Step>> {
    return this.http.get<Array<Step>>(`${environment.baseurl}testcase/steps/${testCaseUid}/${testCaseVersion}`, {withCredentials: true})
      .pipe(catchError(this.handleError));
  }

  getLatestTestCaseSteps(testCaseUid: string): Observable<Array<Step>> {
    return this.http.get<Array<Step>>(`${environment.baseurl}testcase/steps/${testCaseUid}`, {withCredentials: true})
      .pipe(catchError(this.handleError));
  }

  getTestCaseDetails(testCaseUid: string, testCaseVersion: number): Observable<TestCase> {
    const headers = new HttpHeaders()
      .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<TestCase>(`${environment.baseurl}testcase/details/${testCaseUid}/${testCaseVersion}`, {withCredentials: true, headers})
      .pipe(catchError(this.handleError));
  }

  getLatestTestCaseDetails(testCaseUid: string): Observable<TestCase> {
    const headers = new HttpHeaders()
      .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<TestCase>(`${environment.baseurl}testcase/details/${testCaseUid}`, {withCredentials: true, headers})
      .pipe(catchError(this.handleError));
  }

  getAlternativesForTestCase(testCaseUid: string, testCaseVersion: number): Observable<Array<TestCaseAlternative>> {
    const headers = new HttpHeaders()
      .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<Array<TestCaseAlternative>>(`${environment.baseurl}testcase/alternatives/${testCaseUid}/${testCaseVersion}`, {withCredentials: true, headers})
      .pipe(catchError(this.handleError));
  }

  getAlternativesForLatestTestCase(testCaseUid: string): Observable<Array<TestCaseAlternative>> {
    const headers = new HttpHeaders()
      .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<Array<TestCaseAlternative>>(`${environment.baseurl}testcase/alternatives/${testCaseUid}`, {withCredentials: true, headers})
      .pipe(catchError(this.handleError));
  }
}
