import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseAPIService {

  constructor() { }

  // handle error and return a formatted message
  handleError(error: Response | any) {
    // TODO: format text. Maybe just have default text for specific error codes (404, 500...)
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.text() || '';
      const err = body || JSON.stringify(body);
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    return throwError(errMsg);
  }

  returnError(error: Response | any) {
    return throwError(error);
  }

}
