import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AuthService } from 'src/services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (req.url.indexOf(environment.baseurl) != -1 || req.url.indexOf(environment.reportsapiurl) != -1) {
            // send cloned request with header to the next handler.
            return this.auth.userData$.pipe(
                map(userData => {
                    if (userData.sessionID) {
                        return req.clone({
                            headers: req.headers.set('Authorization', userData.sessionID!)
                        })
                    } {
                        return req
                    }
                }),
                switchMap(authReq => next.handle(authReq).pipe(catchError(x => this.handleAuthError(x))))
            )
        }
        // send cloned request with header to the next handler.
        return next.handle(req)
    }

    handleAuthError(err: HttpErrorResponse): Observable<any> {
        if (err.status === 401) {
            this.auth.clearSession();
        }
        return of(null)//throwError(err.message);
    }
}
