import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPIService } from './base-api.service';
import { User } from 'src/models/users';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersAndGroupsService extends BaseAPIService {

  constructor(private http: HttpClient) {
    super();
  }

  getUser(): Observable<User> {
    return this.http.get<User>(`${environment.baseurl}user/`, {withCredentials: true})
      .pipe(catchError(this.handleError));
  }

  getUsers(): Observable<Array<User>> {
    return this.http.get<Array<User>>(`${environment.baseurl}users/`, {withCredentials: true})
      .pipe(catchError(this.handleError));
  }

  getUsersRaw(): Observable<string> {
    return this.http.get(`${environment.baseurl}userRaw/`, {responseType: 'text', withCredentials: true})
      .pipe(catchError(this.handleError));
  }

  getUsersFromSameGroup(): Observable<Array<User>> {
    return this.http.get<Array<User>>(`${environment.baseurl}usersInSameOrganization/`, {withCredentials: true})
            .pipe(catchError(this.handleError));
  }

  getAllUsersFromOrganization(organization: string): Observable<Array<User>> {
    return this.http.get<Array<User>>(`${environment.baseurl}usersInOrganization/${organization}`, {withCredentials: true})
  }

  getAllGroups(): Observable<Array<string>> {
    return this.http.get<Array<string>>(`${environment.baseurl}allGroups/`, {withCredentials: true})
            .pipe(catchError(this.handleError));
  }

  getAllUsersGroups(group: string): Observable<Array<User>> {
    return this.http.get<Array<User>>(`${environment.baseurl}usersInGroup/${group}`, {withCredentials: true})
  }

  getAllUsers(): Observable<Array<User>> {
    return this.http.get<Array<User>>(`${environment.baseurl}allUsers/`, {withCredentials: true})
  }
}
