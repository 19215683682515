import { Component, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TestAnalysis } from 'src/models/testAnalysis';
import { TestSpecification } from 'src/models/testSpecification';
import { TestSpecificationBaseService } from 'src/services/test-specification.base.service';
import { TmtLoggerService } from 'tmt-logger';

@Component({
  selector: 'app-test-specification-details',
  templateUrl: './test-specification-details.component.html',
  styleUrls: ['./test-specification-details.component.css']
})
export class TestSpecificationDetailsComponent implements OnChanges {
  testSpecification: TestSpecification;
  selectedTestSpecificationVersion: number;
  @Input() testSpecificationUid: string;
  @Input() testSpecificationVersion: number;
  displayedTestAnalysesColumns: string[] = ['Name', 'Version', 'RegTime', 'RegBy', 'UID'];
  dataSource: MatTableDataSource<TestAnalysis>;

  constructor(private testSpecificationBaseService: TestSpecificationBaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loggerService: TmtLoggerService) {
    //Below lines added to be able to refresh this component so link to TS details from TS panel shows correct TS in heading on test-suite comppnent
    //Works together with {onSameUrlNavigation: 'reload'} which is added to app-routing module
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  ngOnChanges() {
    if (this.testSpecificationVersion !== undefined) {
      this.getDetails(this.testSpecificationUid, this.testSpecificationVersion);
    }
    else {
      this.getLatestDetails(this.testSpecificationUid);
    }

  }

  getDetails(testSpecificationUid: string, testSpecificationVersion: number) {
    this.testSpecificationBaseService.getTestSpecificationDetails(testSpecificationUid, testSpecificationVersion).subscribe(
      (ts) => {
        this.testSpecification = ts;
        this.selectedTestSpecificationVersion = ts.Version;
        this.dataSource = new MatTableDataSource<TestAnalysis>(ts.TestAnalyses);
      },
      error => this.loggerService.logError(error)
    );

  }

  getLatestDetails(testSpecificationUid: string) {
    this.testSpecificationBaseService.getLatestTestSpecificationDetails(testSpecificationUid).subscribe(
      (ts) => {
        this.testSpecification = ts;
        this.selectedTestSpecificationVersion = ts.Version;
        this.dataSource = new MatTableDataSource<TestAnalysis>(ts.TestAnalyses);
      },
      error => this.loggerService.logError(error)
    );
  }

  getVersion(versionNo: number) {
    if (!versionNo) {
      this.getLatestDetails(this.testSpecificationUid);

      const queryParams: Params = { uid: this.testSpecificationUid };
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    else {
      this.getDetails(this.testSpecificationUid, versionNo);

      const queryParams: Params = { uid: this.testSpecificationUid, version: versionNo };
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
  }
}
