import { Component, Input, OnChanges } from '@angular/core';
import { FPC } from 'src/models/sops';

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.css']
})
export class EnvironmentComponent implements OnChanges {

  constructor() { }

  @Input() VINNo: string;
  @Input() FPCs: FPC[];
  VINNumber: string;
  FPCCodes: string[];
  chosenFPCs: FPC[];

  ngOnChanges(): void {
    this.VINNumber = this.VINNo;
    this.getFPCs(this.FPCs);
  }

  getFPCs(fpcs: FPC[]) {

    this.FPCCodes = ['1', '17', '408', '448', '459', '7991'];

    this.chosenFPCs = fpcs?.filter(x => this.FPCCodes.includes(x.Name));
  
  }
}
    