<tds-header class="te-header">

    <!-- Add with new components -->
    <!-- <ul class="nav nav-tabs nav-tabs-light" style="text-align: left;">
        @if(environment === TargetEnvironment.Hero){
        <li title="TestTools Help site"><a href="https://help.tms.scania.com/#TL%20Results" target="_blank">Help</a>
        </li>
        }
        @if(environment === TargetEnvironment.Hero){
        <li title="Support channel on Teams">
            <a href="https://teams.microsoft.com/l/channel/19%3af0563924f10948e29b24c3580bfa34a6%40thread.tacv2/General?groupId=34884b53-40a8-4388-b61a-a801abce4000&tenantId=3bc062e4-ac9d-4c17-b4dd-3aad637ff1ac"
                target="_blank">Test Tools Support</a>
        </li>
        }
        @if(environment === TargetEnvironment.Hero){
        <li title="Logged in user, link to Reflex">
            <a href="https://reflex.scania.com/profile/{{this.loggedInUser?.toUpperCase()}}" target="_blank">
                <i class="scania-icon-my-scania-mobile xs"></i>{{this.loggedInUser?.toUpperCase()}}</a>
        </li>
        } @else {
        <li title="Logged in user">
            <span><i class="scania-icon-my-scania-mobile xs"></i>{{this.loggedInUser?.toUpperCase()}}</span>
        </li>
        }
    </ul> -->

    <a routerLink="/">
        <tds-header-title>Fenix Test Results {{fullEnvString}}</tds-header-title>
    </a>
    <tds-header-dropdown
    slot="end"
    placement="end"
    no-dropdown-icon
    class="te-hide te-xs-show">
    <img
      slot="icon"
      src="https://www.svgrepo.com/show/384676/account-avatar-profile-user-6.svg"
      alt="User menu." />
    <tds-header-dropdown-list size="lg">
      <tds-header-dropdown-list-user rx
        header="{{userName$ | async}}"
        subheader="{{companyName$ | async}}"></tds-header-dropdown-list-user>
    </tds-header-dropdown-list>
  </tds-header-dropdown>
</tds-header>
<div class="main-table col-md-12">
    <mat-tab-nav-panel #tabPanel><router-outlet></router-outlet></mat-tab-nav-panel>
</div>
