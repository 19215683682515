import { Component, Input } from '@angular/core';

import { GalleriaModule } from 'primeng/galleria';
import { combineLatest, from, map, mergeMap, ReplaySubject, take, toArray } from 'rxjs';

import { environment } from 'src/environments/environment';
import { TargetEnvironment } from 'src/environments/environment.interfaces';
import { FileService } from 'src/services/file.service';

@Component({
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
  providers: [ GalleriaModule ]
})
export class ImagesComponent {
  @Input({ required: true }) set linkUid(v) {
    this.linkUidSubject.next(v);
  }
  private linkUidSubject = new ReplaySubject<string>(1);

  environment = environment.environment
  TargetEnvironment = TargetEnvironment
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  displayBasic: boolean;
  displayBasic2: boolean;
  displayCustom: boolean;
  activeIndex: number = 0;

  private validImageFormats: Set<string> = new Set(['apng', 'avif', 'gif', 'jpg', 'jpeg', 'png', 'svg', 'webp'])

  public images$ = combineLatest({files: this.fileService.attachmentFiles$, linkUid: this.linkUidSubject}).pipe(
    map(data => data.files.filter(file => file.LinkToTestItemUid === data.linkUid)),
    map(files => files.filter(file => this.validImageFormats.has(file.FileName.split('.').pop()))),
    mergeMap(files => from(files).pipe(
      mergeMap(file => this.fileService.getFileByFileUid$(file.FileUid).pipe(take(1))),
      map(file => {
        return {
          itemImageSrc: URL.createObjectURL(file),
          title: file.name
        }
      }),
      toArray()
    ))
  )

  constructor(private fileService: FileService) { }

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }
}
