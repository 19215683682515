<div class="col-md-12 no-padding">
  <div class="col-md-10 no-padding">
    <h4>E2 parameters for test run</h4>
  </div>
  <div class="col-md-2 align-right no-padding">
    <div class="col-md-12 no-padding">
      <input autofocus type="text" id="txtSearchEcuParam" class="form-control" placeholder="Filter..."
        (keyup)="applyFilter($event)" />
    </div>
  </div>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="EcuFamily">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Ecu family </th>
        <td mat-cell *matCellDef="let element"> {{element.EcuFamily}} </td>
      </ng-container>
      <ng-container matColumnDef="EcuName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Ecu name </th>
        <td mat-cell *matCellDef="let element"> {{element.EcuName}} </td>
      </ng-container>
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'texttruncated':element.Name}"> {{element.Name}} </td>
      </ng-container>
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
      </ng-container>
      <ng-container matColumnDef="Value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
        <td mat-cell *matCellDef="let element"> {{element.Value}} </td>
      </ng-container>
      <ng-container matColumnDef="EnumValue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Enum value </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'texttruncated':element.EnumValue}"> {{element.EnumValue}}
        </td>
      </ng-container>
      <ng-container matColumnDef="ValueType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Value type </th>
        <td mat-cell *matCellDef="let element"> {{element.ValueType}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="getPageSizeOptions()" showFirstLastButtons></mat-paginator>
  </div>
</div>
