import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { UserData } from 'src/models/user-data.model';
import { Config } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { TmtLoggerService } from 'tmt-logger';

@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loggerService: TmtLoggerService

  ) { }

  private userDataSubject: BehaviorSubject<UserData> = new BehaviorSubject({
    sessionID: localStorage.getItem('sessionId'),
    userName: localStorage.getItem('userName'),
    isLoggedIn: !!localStorage.getItem('sessionId'),
    companyName: localStorage.getItem('companyName')
  } as UserData)

  public userData$ = this.userDataSubject.asObservable()

  public isLoggedIn$ = this.userData$.pipe(
    map(userData => !!userData.sessionID || !!localStorage.getItem('sessionId'))
  )


  public startedRedirect = false;

  public sessionID$ = this.userData$.pipe(
    map(userData => userData.sessionID ?? localStorage.getItem('sessionId'))
  )

  clearSession(): void {
    this.userDataSubject.next({ userName: '', sessionID: '', isLoggedIn: false, companyName: '' })

    // Clear data from local storage
    localStorage.removeItem('sessionId');
    localStorage.removeItem('userName');
    localStorage.removeItem('companyName');
  }

  DoLogin(sessionId: string, userName: string, companyName: string): Observable<any> {

    // bypass Cola
    if (Config.colaConfig.disabled) {
      const dummyUserData = {
        sessionID: 'unknown',
        userName: 'unknown',
        companyName: 'Scania',
        isLoggedIn: true
      }
      this.userDataSubject.next(dummyUserData)

      return of([dummyUserData])
    }

    if (sessionId != '') {
      return of({
        sessionID: sessionId,
        userName: userName,
        companyName: companyName,
        isLoggedIn: true
      }).pipe(
        tap(data => {
          this.userDataSubject.next(data)
          localStorage.setItem('sessionId', data.sessionID)
          localStorage.setItem('userName', data.userName)
          localStorage.setItem('companyName', data.companyName)
          this.removeSpecificQueryParams(['sessionId', 'username', 'company'])
        })
      )
    }
    else  {
      return this.isLoggedIn$.pipe(
        filter(isLoggedIn => !isLoggedIn),
        map(() => {
          const host = encodeURIComponent(window.location.href)
          const loginUrl = `${Config.colaConfig.redirectUrl}?origin=${host}`
          if (this.startedRedirect === false) {
            this.startedRedirect = true;
            this.loggerService.flush()
            document.location.href = loginUrl;
          }
        })
      )
    }

  }

  removeSpecificQueryParams(paramsToRemove: string[]) {
    // Get the current query parameters
    const queryParams = { ...this.route.snapshot.queryParams };

    // Remove specific parameters
    paramsToRemove.forEach(param => delete queryParams[param]);

    // Construct a new URL without the removed parameters
    const urlTree = this.router.createUrlTree([], { queryParams });

    // Navigate to the new URL
    this.router.navigateByUrl(urlTree);
  }
}
