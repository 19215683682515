<div class="row left-margin right-margin">
    <div class="col-md-12" [ngClass]="{'loader' : gettingTestSpecification}"></div>
    @if (testSpecificationExist && !gettingTestSpecification) {
    <div class="col-md-12">
        <div id="divTestSpecificationHeading" class="col-md-12 no-padding bottom-margin">
            <div class="results-heading">
                <h2>Details page for test specification: {{testSpecification?.ItemNo}} {{testSpecification?.Name}}</h2>     
            </div>
            <div class="results-heading-details">
                &nbsp;(version {{testSpecification?.Version}}) - {{testSpecification?.RegTime | date: 'yyyy-MM-dd HH:mm:ss'}} -
                {{testSpecification?.RegBy | uppercase}}&nbsp;
                <span [routerLink]="['/testspecification/', testSpecification?.UID]" class="scania-link small-font">[View all results for this test specification]</span>
            </div>   
        </div>
         <div>
            <button mat-button (click)="accordion.openAll()" class="btn btn-sm btn-primary">Expand all panels</button>
            <button mat-button (click)="accordion.closeAll()" class="btn btn-sm btn-default">Collapse all panels</button>
        </div>
        <mat-accordion multi> 
            <div class="bottom-margin-small">
                <mat-expansion-panel [expanded]="true" class="bottom-margin">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Details
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div id="divTestpecificationDetails" *ngIf="queryParamUid">
                        <app-test-specification-details [testSpecificationUid]='queryParamUid' [testSpecificationVersion]='queryParamVersion'></app-test-specification-details>
                    </div>
                </mat-expansion-panel>
            </div>
        </mat-accordion> 
    </div>
    }
    @else if (!testSpecificationExist && !gettingTestSpecification) { 
        <div>Test specification not found</div>
    }

</div>

