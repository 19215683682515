import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { from, map, Observable, of, ReplaySubject, shareReplay, switchMap } from 'rxjs';
import { TestFileType } from 'src/enums/testFileType';
import { FileMetadata } from 'src/models/fileMetadata';
import { GraphqlFileMetadata } from 'src/models/graphql/graphqlFileMetadata';
import { GraphqlFileOutput } from 'src/models/graphql/graphqlFileOutput';
import { ApolloService } from 'src/services/apollo.service';

/**
 * Handles file-related tasks, such as getting file metadata and file contents.
 */
@Injectable()
export class FileService {

  /**
   * Subject containing current test run UID. Used to get files for current test run.
   */
  private testRunSubject: ReplaySubject<string> = new ReplaySubject(1)

  /**
   * Pipes current test run UID to get file metadata for input test run.
   */
  public fileMetadata$ = this.testRunSubject.pipe(
    switchMap(testRunUid => {
      const query = gql`query fileHandlerFileMetadataByTestRun($TestRunUid: String!) {
        fileHandlerFileMetadataByTestRun(TestRunUid: $TestRunUid) {
          FileName
          FileUid
          CreateDate
          LinkToTestItemUid
          FileType
        }
      }`;

      return this.apolloService.apolloClients$.pipe(
        switchMap(clients =>
          clients['abstractionLayerClient'].query<{fileHandlerFileMetadataByTestRun: GraphqlFileMetadata[]}>({
            query: query,
            variables: { TestRunUid: testRunUid}
          })
        ),
        map(response => {
          const result = response.data?.fileHandlerFileMetadataByTestRun;
          return result?.length ? result.map(item => new FileMetadata(item)) : [];
        })
      )
    }),
    shareReplay(1)
  )

  /**
   * Following observables select specific file types from list of file metadata for current test run.
   * Use to get information about specific files connected to test run.
   */
  public xcomFiles$ = this.fileMetadata$.pipe(map(files => files.filter(file => file.FileType === TestFileType.Xcom)));
  public e2Files$ = this.fileMetadata$.pipe(map(files => files.filter(file => file.FileType === TestFileType.E2)));
  public atsFiles$ = this.fileMetadata$.pipe(map(files => files.filter(file => file.FileType === TestFileType.Ats)));
  public offboardFiles$ = this.fileMetadata$.pipe(map(files => files.filter(file => file.FileType === TestFileType.OffboardAgent || file.FileType === TestFileType.OffboardGadget)));
  public attachmentFiles$ = this.fileMetadata$.pipe(map(files => files.filter(file => file.FileType === TestFileType.TestCaseAttachment)))

  /**
   * Constructor.
   * @param apolloService To get data from graphql server.
   */
  constructor(private apolloService: ApolloService) { }

  /**
   * Get a file by its UID.
   * @param fileUid UID of the file to get.
   * @returns File definition matching input parameters.
   */
  public getFileByFileUid$(fileUid: string): Observable<File | undefined> {

    const query = gql`query fileHandlerRetrieveFileContent($FileUid: String!) {
      fileHandlerRetrieveFileContent(FileUid: $FileUid) {
        FileUid
        FileName
        CreateDate
        Base64
        Checksum
      }
    }`;

    return this.apolloService.apolloClients$.pipe(
      switchMap(clients =>
        clients['abstractionLayerClient'].query<{fileHandlerRetrieveFileContent: GraphqlFileOutput}>({
          query: query,
          variables: {
            FileUid: fileUid
          }
        })
      ),
      map(response => {
        const file = response.data.fileHandlerRetrieveFileContent

        if (!file) {
          return undefined
        }

        // Decode base64 string to a binary string
        const binaryString = atob(file.Base64);

        // Convert binary string to Uint8Array
        const len = binaryString.length;
        const bytes = new Uint8Array(len);

        // convert bytes back into original characters.
        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        // Create File object to return.
        const blob = new Blob([bytes])
        return new File([blob], file.FileName ?? '', { lastModified: new Date(file.CreateDate).valueOf()})
      })
    )
  }


  /**
   * Initializes service with current test run UID which is used to get linke files.
   * @param testRunUid UID of current test run.
   */
  public initFilesInformationForTestRun(testRunUid: string){
    this.testRunSubject.next(testRunUid);
  }

  /**
   * Get files for current test run run, filtered by files matching a specific test result, using the LinkUid of the result.
   * @param linkUid Link UID to filter results by.
   * @returns List of files matching conditions.
   */
  public getFilesInformationForTestResult$(linkUid: string): Observable<FileMetadata[]> {
    return this.fileMetadata$.pipe(
      map(results => results.filter(item => item.LinkToTestItemUid === linkUid))
    );
  }

  public downloadFile(file: File): void {
    if(!file) {
      return
    }
    const url = window.URL.createObjectURL(file)
    const a = document.createElement('a')
    document.body.appendChild(a)
    a.setAttribute('style', 'display: none')
    a.href = url
    a.download = file.name
    a.click()
    window.URL.revokeObjectURL(url)
    a.remove()
  }

  public getFilestringFromFile$(file: File): Observable<string> {
    if(!file.arrayBuffer) {
      return of('')
    }
    return from(file.arrayBuffer()).pipe(
      map(arrayBuffer => {
        const decoder = new TextDecoder('utf-8');
        return decoder.decode(arrayBuffer)
      })
    )
  }
}

