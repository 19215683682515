import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { TEST_SPECIFICATION_QUERY } from "src/graphql/queries/abstraction-layer-queries";
import { TestSpecification } from "src/models/testSpecification";
import { ApolloService } from "./apollo.service";
import { TestSpecificationBaseService } from "./test-specification.base.service";


@Injectable({ providedIn: 'root' })
export class TestSpecificationGraphQLService extends TestSpecificationBaseService {

  constructor(private apolloService: ApolloService) { super(); }

  getTestSpecification(uid: string, testSpecificationVersion?: number): Observable<TestSpecification> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsTestSpecification: TestSpecification }>({
          query: TEST_SPECIFICATION_QUERY,
          variables: {
            Uid: uid,
            version: testSpecificationVersion
          }
        })
      }),
      map(result => {
        if (result.data && result.data.resultsTestSpecification) {
          return JSON.parse(JSON.stringify(result.data.resultsTestSpecification))
        } else {
          return new TestSpecification()
        }
      }),
      shareReplay(1)
    );
  }

  getLatestTestSpecification(uid: string): Observable<TestSpecification> {
    return this.getTestSpecification(uid)
  }

  getTestSpecificationDetails(testSpecificationUid: string, testSpecificationVersion: number): Observable<TestSpecification> {
    return this.getTestSpecification(testSpecificationUid, testSpecificationVersion)
  }
  getLatestTestSpecificationDetails(testSpecificationUid: string): Observable<TestSpecification> {
    return this.getTestSpecification(testSpecificationUid)
  }

}
