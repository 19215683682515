import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { TestRunViewModel, TestSuite } from '../../../../models/testRun';
import { TmtLoggerService } from 'tmt-logger';
import { TestSuiteBaseService } from 'src/services/test-suite.base.service';
import { TestRunBaseService } from 'src/services/test-run.base.service';
@Component({
  selector: 'app-test-suites',
  templateUrl: './test-suites.component.html',
  styleUrls: ['./test-suites.component.css']
})
export class TestSuitesComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscription: Subscription;
  displayedColumns: string[] = ['TestRun.TestRunUid', 'TestRun.Environment', 'TestSuiteVersion', 'TestRun.ExecutionTime', 'TestRun.RegBy',
    'TestSuite.Name', 'TestSuite.Version'];
  dataSource: MatTableDataSource<TestRunViewModel>;
  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    if (paginator && this.testSuites)
      this.dataSource.paginator = paginator
  }
  @ViewChild(MatSort) sort: MatSort;
  maxall = 10;
  testSuites: TestRunViewModel[];
  activeTestSuite: TestSuite;
  gettingTestSuites: boolean = false;

  constructor(private testRunBaseService: TestRunBaseService,
    private testSuiteBaseService: TestSuiteBaseService,
    private route: ActivatedRoute,
    private router: Router,
    private loggerService: TmtLoggerService) { }



  ngOnInit(): void {
    this.gettingTestSuites = false;
    this.route.url.pipe(switchMap(segment =>
      this.testSuiteBaseService.getLatestTestSuite(segment[1].path)
    )).subscribe(ts => this.activeTestSuite = ts);
    this.getTestSuites();
  }

  // sorting and paging does not work unless the fetch of test suites also is done in AfterViewInit
  ngAfterViewInit(): void {
    this.route.url.pipe(switchMap(segment =>
      this.testSuiteBaseService.getLatestTestSuite(segment[1].path)
    )).subscribe(ts => this.activeTestSuite = ts);
    this.getTestSuites();
  }

  getTestSuites(): void {
    this.gettingTestSuites = true;
    this.subscription = this.route.url.pipe(switchMap(urlSegment =>
      this.testRunBaseService.getTestRunsByTestSuiteUid(urlSegment[1].path)
    )).subscribe(testsuitedata => {
      this.testSuites = testsuitedata;
      const dataSource = new MatTableDataSource<TestRunViewModel>(this.testSuites);
      // set a new sortingDataAccessor to be able to sort nested objects
      dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'TestRun.TestRunUid': return item.TestRun.TestRunUid;
          case 'TestRun.Environment': return item.TestRun.Environment;
          case 'TestSuiteVersion': return item.TestSuiteVersion;
          case 'TestRun.ExecutionTime': return item.TestRun.ExecutionTime;
          case 'TestRun.RegBy': return item.TestRun.RegBy;
          case 'TestSuite.Name': return item.TestSuite.Name;
          case 'TestSuite.Version': return item.TestSuite.Version;
          default: return item[property];
        }
      };
      dataSource.sort = this.sort;
      // set a new filterPredicate function to be able to filter nested objects
      dataSource.filterPredicate = (data, filter: string) => {
        const accumulator = (currentTerm, key) => {
          return this.nestedFilterCheck(currentTerm, data, key);
        };
        const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
        const transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) !== -1;
      };
      this.dataSource = dataSource;
      this.gettingTestSuites = false;
    },
      error => {
        this.loggerService.logError(error),
          this.gettingTestSuites = false;
      }
    );
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.paginator && this.dataSource.paginator.length > this.maxall) {
      return [10, 20, this.dataSource.paginator.length];
    } else {
      return [10, 20];
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  navigateTo(row: any) {
    this.router.navigate(['/testresult/testrun/' + row.TestRun.TestRunUid]);
  }

}
