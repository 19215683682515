import { Injectable, Optional } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TestRunStatistics, TestRunViewModel } from 'src/models/testRun';
import { Property } from 'src/models/property';
import { environment } from 'src/environments/environment';
import { BaseAPIService } from './base-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { UtilsService } from './utils.service';
@Injectable({
  providedIn: 'root'
})
export class TestRunService extends BaseAPIService {

  constructor(private http: HttpClient, private utilsService: UtilsService) { super(); }

  public selectedRunUid = new Subject<string>();
  
  getTestRun(uid: string): Observable<TestRunViewModel> { 
      const headers = new HttpHeaders()
      .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
      return this.http.get<TestRunViewModel>(`${environment.baseurl}testrun/${uid}`, {withCredentials: true, headers})
      .pipe(catchError(this.handleError));
  }

  getTestRunsByDate(startDate: string, endDate: string): Observable<Array<TestRunViewModel>> {
    const headers = new HttpHeaders()
      .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<Array<TestRunViewModel>>(`${environment.baseurl}testruns/?startdate=${startDate}&enddate=${endDate}`,
                                                 {withCredentials: true, headers})
    .pipe(catchError(this.handleError));
  }

  getTestRunsByTestCaseUid(uid: string): Observable<Array<TestRunViewModel>> {
    const headers = new HttpHeaders()
      .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<Array<TestRunViewModel>>(`${environment.baseurl}testruns/testcase/${uid}`, {withCredentials: true, headers})
      .pipe(catchError(this.handleError));
  }

  async getTestRunsByTestCaseUid2(uid: string): Promise<Array<TestRunViewModel>> {
    const headers = new HttpHeaders()
      .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    var result = await this.http.get<Array<TestRunViewModel>>(`${environment.baseurl}testruns/testcase/${uid}`, {withCredentials: true, headers}).toPromise();
    return result;
      //.pipe(catchError(this.handleError));
  }

  getTestRunsByTestSuiteUid(uid: string): Observable<Array<TestRunViewModel>> {
    const headers = new HttpHeaders()
    .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<Array<TestRunViewModel>>(`${environment.baseurl}testruns/testsuite/${uid}`, {withCredentials: true, headers})
      .pipe(catchError(this.handleError));
  }

  getTestRunsByTestSpecificationUid(uid: string): Observable<Array<TestRunViewModel>> {
    const headers = new HttpHeaders()
    .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<Array<TestRunViewModel>>(`${environment.baseurl}testruns/testspecification/${uid}`, {withCredentials: true, headers})
      .pipe(catchError(this.handleError));
  }

  getTestRunsByProperty(parameters: string): Observable<Array<TestRunViewModel>> {
    return this.http.get<Array<TestRunViewModel>>(`${environment.baseurl}testruns/property/${parameters}`, {withCredentials: true})
    .pipe(catchError(this.handleError));
  }

  getTestRunStatistics(uid: string): Observable<TestRunStatistics> {
    return this.http.get<TestRunStatistics>(`${environment.baseurl}testrun/statistics/${uid}`, {withCredentials: true})
    .pipe(catchError(this.handleError));
  }

  getTestRunProperties(uid: string): Observable<Array<Property>> {
    return this.http.get<Array<Property>>(`${environment.baseurl}testrun/properties/${uid}`, {withCredentials: true})
      .pipe(catchError(this.handleError));
  }

}
