<h1 mat-dialog-title>History</h1>
<div mat-dialog-content>
    <div class="mat-elevation-z0"></div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Before">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Value before </th>
        <td mat-cell *matCellDef="let element"> {{element.Before}} </td>
      </ng-container>
      <ng-container matColumnDef="After">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Value after </th>
        <td mat-cell *matCellDef="let element"> {{element.After}} </td>
      </ng-container>
      <ng-container matColumnDef="RegByUser">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="150px"> Reg by user </th>
        <td mat-cell *matCellDef="let element"> {{element.RegByUser}} </td>
      </ng-container>
      <ng-container matColumnDef="RegTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header width="150px"> Reg time </th>
        <td mat-cell *matCellDef="let element"> {{element.RegTime | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
      </ng-container> 
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <mat-paginator [pageSizeOptions]="getPageSizeOptions()" showFirstLastButtons></mat-paginator>
  </div>
  
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close class="btn btn-primary">Close</button>
  </div>
 

