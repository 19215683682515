import { Component, EventEmitter, Input, Output, ViewChild, OnChanges } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { OffboardComponentWithFile } from 'src/models/offboardComponent';
import { DownloadService } from 'src/services/download.service';
import { File } from '../../../../models/file';
import { TmtLoggerService } from 'tmt-logger';
import { OffboardComponentBaseService } from 'src/services/offboard-component.base.service';
import { TargetEnvironment } from 'src/environments/environment.interfaces';

@Component({
  selector: 'app-offboard-component',
  templateUrl: './offboard-component.component.html',
  styleUrls: ['./offboard-component.component.css']
})
export class OffboardComponentComponent implements OnChanges {

  constructor(private offboardComponentBaseService: OffboardComponentBaseService,
    private downloadService: DownloadService,
    private loggerService: TmtLoggerService) { }

  offboardComponents: OffboardComponentWithFile[];
  displayedColumns: string[] = ['OffboardComponent.Value', 'OffboardComponent.Version'];
  displayedColumnsForFiles: string[] = ['Name', 'ImportTime'];
  dataSource;
  dataSourceFiles;
  countOffboardComponentFiles: number;
  maxall = 10;
  baseUrl = environment.baseurl;
  environment = environment.environment
  TargetEnvironment = TargetEnvironment
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() runUid: string;
  @Output() countOffboardComponents: EventEmitter<any> = new EventEmitter();

  ngOnChanges() {
    this.getDataComponents(this.runUid);
    if (this.environment != TargetEnvironment.Hero)
      this.getDataComponentFiles(this.runUid);
  }

  getDataComponents(runUid: string) {
    this.offboardComponentBaseService.getOffboardComponents(runUid)
      .subscribe(data => {
        this.offboardComponents = data;
        this.countOffboardComponents.emit(this.offboardComponents.length);
        this.dataSource = new MatTableDataSource<OffboardComponentWithFile>(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // }
      },
        error => this.loggerService.logError(error)
      );
  }

  getDataComponentFiles(runUid: string) {
    this.downloadService.getOffboardComponentFiles(runUid)
      .subscribe(data => {
        this.dataSourceFiles = new MatTableDataSource<File>(data);
        this.countOffboardComponentFiles = this.dataSourceFiles.data.length;
      },
        error => this.loggerService.logError(error)
      );
  }

  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.paginator && this.dataSource.paginator.length > this.maxall) {
      return [10, 20, this.dataSource.paginator.length];
    } else {
      return [10, 20];
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
