import { Component, DestroyRef, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FileParserService } from './../../../../services/file-parser.service';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { of, switchMap, tap } from 'rxjs';

import { EcuInformation } from 'src/models/ecuInformation';
import { FileService } from 'src/services/file.service';
import { TmtLoggerService } from 'tmt-logger';

@Component({
  selector: 'app-ecu-readout',
  templateUrl: './ecu-readout.component.html',
  styleUrls: ['./ecu-readout.component.css']
})
export class EcuReadoutComponent {

  private destroyRef = inject(DestroyRef)

  public fileData$ = this.fileService.xcomFiles$.pipe(
    switchMap(files => {
      if (!files || !files.length) {
        return of([])
      }

      return this.fileService.getFileByFileUid$(files[0].FileUid).pipe(
        switchMap(file => {
          return this.fileService.getFilestringFromFile$(file)
        }),
        tap(fileString => {
          const items = this.fileParserService.parseXcom(fileString);

          this.dataSource = new MatTableDataSource<EcuInformation>(items);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          this.countEcuReadouts.emit(items.length);
        })
      )
    })
  );

  @Output() countEcuReadouts: EventEmitter<any> = new EventEmitter();

  constructor(
    private fileService: FileService,
    private fileParserService: FileParserService,
    private loggerService: TmtLoggerService) {

      // Subscribe to file data, since observables with Tegel are a pain. TODO: Replace with better alternative.
      this.fileData$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }


  displayedColumns: string[] = ['Ecu', 'ID', 'Description', 'Value'];
  dataSource;
  countEcuReadoutFiles: number;
  maxall = 10;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  uniqueFilter(value, index, self) {
    return self.indexOf(value) === index;
  }

  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.paginator && this.dataSource.paginator.length > this.maxall) {
      return [10, 20, this.dataSource.paginator.length];
    } else {
      return [10, 20];
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
