<div class="col-md-12" *ngIf="alternatives?.length > 0">
    <!-- <div class="col-md-10"></div>
    <div class="col-md-2 align-right no-padding">
        <div class="col-md-12 no-padding">
            <input autofocus type="text" id="txtSearchAlternative" class="form-control" placeholder="Filter..."
                (keyup)="applyFilter($event)" />
        </div>
    </div> -->
    <div class="col-md-12 mat-elevation-z0">
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="Properties">         
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Properties </th>
                <td mat-cell *matCellDef="let element"> 
                    <table >  
                        <tr *ngFor="let prop of element.Properties" >
                            <td width="30%">{{prop.Name}}</td>
                            <td class="bold" *ngIf="!prop.ValueDescription">{{prop.Value}}</td>
                            <td class="bold" *ngIf="prop.ValueDescription?.length > 0" title="{{prop.ValueDescription}}">{{prop.Value}}<span class="scania-icon-help xs" style="padding-bottom: 20px;"></span></td>
                        </tr>
                    </table> 
                </td>
            </ng-container>
            <ng-container matColumnDef="Version">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Version </th>
                <td mat-cell *matCellDef="let element"> {{element.Version}} </td>
            </ng-container>
            <ng-container matColumnDef="TestGroups">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Test groups </th>
                <td mat-cell *matCellDef="let element" class="bold"> {{element.TestGroups?.join(', ')}} </td>
            </ng-container>
            <ng-container matColumnDef="RegTime">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Creation time </th>
                <td mat-cell *matCellDef="let element"> {{element.RegTime | date: 'yyyy-MM-dd HH:mm:ss'}} </td>
            </ng-container>
            <ng-container matColumnDef="RegBy">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Created by </th>
                <td mat-cell *matCellDef="let element"> {{element.RegBy}} </td>
            </ng-container>     
            <ng-container matColumnDef="UID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>UID</th>
                <td mat-cell *matCellDef="let element"> {{element.UID}} </td>
            </ng-container>
           
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="navigateTo(row)"></tr> -->
        </table>
        <!-- <mat-paginator [pageSizeOptions]="getPageSizeOptions()" showFirstLastButtons></mat-paginator> -->
    </div>
</div>

<div *ngIf="alternatives?.length === 0" class="normal-text">No test suite alternatives found</div>
