<div>
  <button mat-button (click)="accordion.openAll()" class="btn btn-sm btn-primary">Expand all SOPS panels</button>
  <button mat-button (click)="accordion.closeAll()" class="btn btn-sm btn-default">Collapse all SOPS panels</button>
</div>
<mat-accordion multi>   
    <mat-expansion-panel [expanded]="false" class="bottom-margin">
      <mat-expansion-panel-header>
        <mat-panel-title>
          FPCs <span class='badge badge-default'>{{this.countFPCs}}</span>  
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div id="divFPCs" >
        <div class="col-md-12 no-padding">
            <div class="col-md-10 no-padding">
              <h4>FPCs for test run </h4>
            </div>
            <div class="col-md-2 align-right no-padding">
              <div class="col-md-12 no-padding">
                <input autofocus type="text" id="txtSearchFPCs" class="form-control" placeholder="Filter..."
                  (keyup)="applyFilterFPCs($event)" />
              </div>
            </div>
            <div>
              <table mat-table [dataSource]="dataSourceFPCs" matSort #matSortFPCs="matSort">
                <ng-container matColumnDef="Name">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Family</th>
                  <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                </ng-container>
                <ng-container matColumnDef="Value">
                  <th mat-header-cell *matHeaderCellDef width="10%"> Variant </th>
                  <td mat-cell *matCellDef="let element"> {{element.Value}} </td>
                </ng-container>
                <ng-container matColumnDef="FamilyDescription">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="30%"> Family Description </th>
                    <td mat-cell *matCellDef="let element"> {{element.FamilyDescription}} </td>
                </ng-container>
                <ng-container matColumnDef="VariantDescription">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Variant Description </th>
                <td mat-cell *matCellDef="let element"> {{element.VariantDescription}} </td>
                </ng-container>    
                <tr mat-header-row *matHeaderRowDef="displayedColumnsFPCs"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsFPCs;"></tr>
              </table>       
              <mat-paginator #paginatorFPCs [pageSizeOptions]="getPageSizeOptionsFPCs()" showFirstLastButtons></mat-paginator>
            </div>
          </div>      
      </div> 
    </mat-expansion-panel>  
    <mat-expansion-panel [expanded]="false" class="bottom-margin">
        <mat-expansion-panel-header>
          <mat-panel-title>
            XPCs <span class='badge badge-default'>{{this.countXPCs}}</span> 
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="divXPCs" >
          <div class="col-md-12 no-padding">
              <div class="col-md-10 no-padding">
                <h4>XPCs for test run</h4>
              </div>
              <div class="col-md-2 align-right no-padding">
                <div class="col-md-12 no-padding">
                  <input autofocus type="text" id="txtSearchXPCs" class="form-control" placeholder="Filter..."
                    (keyup)="applyFilterXPCs($event)" />
                </div>
              </div>
              <div>
                <table mat-table [dataSource]="dataSourceXPCs" matSort  #matSortXPCs="matSort"> 
                  <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="20%"> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
                    <td mat-cell *matCellDef="let element"> {{element.Value}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsXPCs"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsXPCs;"></tr>
                </table>       
                <mat-paginator #paginatorXPCs [pageSizeOptions]="getPageSizeOptionsXPCs()" showFirstLastButtons></mat-paginator>
              </div>
            </div>      
        </div> 
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="false" class="bottom-margin">
        <mat-expansion-panel-header>
          <mat-panel-title>
            ECUs <span class='badge badge-default'>{{this.countFunctionParameters}}</span> 
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="divFunctionParameters" >
          <div class="col-md-12 no-padding">
              <div class="col-md-10 no-padding">
                <h4>ECUs (function parameters) for test run</h4>
              </div>
              <div class="col-md-2 align-right no-padding">
                <div class="col-md-12 no-padding">
                  <input autofocus type="text" id="txtSearchFunctionParameters" class="form-control" placeholder="Filter..."
                    (keyup)="applyFilterFunctionParameters($event)" />
                </div>
              </div>
              <div>
                <table mat-table [dataSource]="dataSourceFunctionParameters" matSort  #matSortFunctionParameters="matSort"> 
                  <ng-container matColumnDef="Family">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Family </th>
                    <td mat-cell *matCellDef="let element"> {{element.Family}} </td>
                  </ng-container>
                  <ng-container matColumnDef="ParentName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> ECU </th>
                    <td mat-cell *matCellDef="let element"> {{element.ParentName}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                  </ng-container>
                  <ng-container matColumnDef="Value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
                    <td mat-cell *matCellDef="let element"> {{element.Value}} </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="displayedColumnsFunctionParameters"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumnsFunctionParameters;"></tr>
                </table>       
                <mat-paginator #paginatorFunctionParameters [pageSizeOptions]="getPageSizeOptionsFunctionParameters()" showFirstLastButtons></mat-paginator>
              </div>
            </div>      
        </div> 
      </mat-expansion-panel>
</mat-accordion>
