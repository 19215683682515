import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { RequirementWithTestAnalysis } from 'src/models/requirement';
import { Subscription } from 'rxjs';
import { RequirementService } from 'src/services/requirement.service';
import { MatTableDataSource } from '@angular/material/table';
import {environment} from 'src/environments/environment';
import { TmtLoggerService } from 'tmt-logger';
import { RequirementBaseService } from 'src/services/requirement.base.service';

@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.css']
})
export class RequirementsComponent implements OnInit, OnChanges {
  requirementsByTestCase: RequirementWithTestAnalysis[];
  requirementsByResult: RequirementWithTestAnalysis[];
  @Input() testCaseName: string;
  @Input() testCaseUid: string;
  @Input() testCaseVersion: number;
  @Input() testResultUid: string;
  @Output() countRequirements: EventEmitter<any> = new EventEmitter();
  private subscription: Subscription;
  dataSource: MatTableDataSource<RequirementWithTestAnalysis>;
  displayedColumns: string[] = ['Requirement.RequirementID', 'Requirement.Version', 'Requirement.ASIL', 'Requirement.Name' ];
  displayedColumnsTA: string[] = ['TestAnalysis.Name', 'TestAnalysis.Version', 'TestAnalysis.UID',  'TestAnalysis.RegTime', 'TestAnalysis.ValidTo'];
  displayedColumnsTAAlt: string[] = ['TestAnalysisAlternative.Name', 'TestAnalysisAlternative.Version',  'TestAnalysisAlternative.UID', 
                              'TestAnalysisAlternative.RegTime', 'TestAnalysisAlternative.ValidTo'];
  requirementsurl = environment.requirementsurl;
  labelShowAll: string;
  reqIsResult: boolean;

  constructor(private requirementBaseService: RequirementBaseService, private requirementService: RequirementService, private loggerService: TmtLoggerService) { }

  ngOnInit(): void {   
  }

  ngOnChanges(changes: SimpleChanges): void {
      this.getRequirements(this.testCaseUid, this.testCaseVersion, this.testResultUid);
      this.labelShowAll = "Show all requirements connected to " + this.testCaseName + " (version " + this.testCaseVersion + ")";
  }

  getRequirements(caseUid: string, caseVersion: number, resultUid: string) {
    this.requirementsByTestCase = null;
    this.requirementsByResult = null;
    this.subscription = this.requirementBaseService.getRequirementsByResult(resultUid).subscribe((resultReq) => {
      this.requirementsByResult = resultReq;
      this.countRequirements.emit(this.requirementsByResult.length);
      this.dataSource = new MatTableDataSource<RequirementWithTestAnalysis>(this.requirementsByResult);
      if (this.requirementsByResult.length > 0) {
        this.requirementsByResult.forEach(r => {
          r.IsResultRequirement = true;
        });
      }
      this.requirementBaseService.getRequirementsByTestCase(caseUid, caseVersion).subscribe((tcReq) => {
        this.requirementsByTestCase = tcReq;
      }
      );
    }),
      error => this.loggerService.logError(error)
  }

  showHistory(isChecked: boolean) {
    if (isChecked) {
      this.requirementsByTestCase.forEach(req => {
        req.IsResultRequirement = false;
        var found = this.requirementsByResult.some(r => {
          if (r.Requirement.RequirementID === req.Requirement.RequirementID &&
            r.Requirement.ASiL === req.Requirement.ASiL &&
            r.Requirement.Version === req.Requirement.Version &&
            r.TestAnalysis.UID === req.TestAnalysis.UID &&
            r.TestAnalysis.Version === req.TestAnalysis.Version &&
            r.TestAnalysisAlternative.UID === req.TestAnalysisAlternative.UID &&
            r.TestAnalysisAlternative.Version === req.TestAnalysisAlternative.Version) {
              req.IsResultRequirement = true;
          }
        })
          this.dataSource = new MatTableDataSource<RequirementWithTestAnalysis>(this.requirementsByTestCase);
          this.countRequirements.emit(this.requirementsByTestCase.length);
      });

    }
    else {
      this.dataSource = new MatTableDataSource<RequirementWithTestAnalysis>(this.requirementsByResult);
      this.countRequirements.emit(this.requirementsByResult.length);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
