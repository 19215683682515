import { Component, OnChanges, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Property } from 'src/models/property';
import {MatPaginator} from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TmtLoggerService } from 'tmt-logger';
import { TestRunBaseService } from 'src/services/test-run.base.service';

@Component({
  selector: 'app-test-run-properties',
  templateUrl: './test-run-properties.component.html',
  styleUrls: ['./test-run-properties.component.css']
})
export class TestRunPropertiesComponent implements OnChanges {

  constructor(private testRunBaseService: TestRunBaseService, private loggerService: TmtLoggerService) { }

  properties: Property[];
  displayedColumns: string[] = ['Name', 'Value', 'Description'];
  dataSource;
  maxall = 10;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() runUid: string;
  @Output() countRunProperties: EventEmitter<any> = new EventEmitter();

  ngOnChanges() {
    this.getProperties(this.runUid);
  }
  
  getProperties(runUid: string) {
    this.testRunBaseService.getTestRunProperties(this.runUid)
      .subscribe (data =>  {
          this.properties = data;
          this.countRunProperties.emit(this.properties.length);
          this.dataSource = new MatTableDataSource<Property>(data);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
      },
      error => this.loggerService.logError(error)
    );
  }

  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.paginator && this.dataSource.paginator.length > this.maxall) {
      return [10, 20, this.dataSource.paginator.length];
    } else {
     return [10, 20];
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
