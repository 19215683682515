import { Component, DestroyRef, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatTableDataSource } from '@angular/material/table';

import { of, switchMap, tap } from 'rxjs';
import { TargetEnvironment } from 'src/environments/environment.interfaces';
import { FileService } from 'src/services/file.service';
import { TmtLoggerService } from 'tmt-logger';

import { environment } from '../../../../environments/environment';
import { EcuParam } from '../../../../models/ecuParam';
import { FileParserService } from './../../../../services/file-parser.service';

/**
 * Displays ECU parameters for test run.
 */
@Component({
  selector: 'app-ecu-param',
  templateUrl: './ecu-param.component.html',
  styleUrls: ['./ecu-param.component.css']
})
export class EcuParamComponent {

  private destroyRef = inject(DestroyRef)

  public fileData$ = this.fileService.e2Files$.pipe(
    switchMap(files => {
      if (!files || !files.length) {
        return of([])
      }

      return this.fileService.getFileByFileUid$(files[0].FileUid).pipe(
        switchMap(file => {
          return this.fileService.getFilestringFromFile$(file)
        }),
        tap(fileString => {
          const items = this.fileParserService.parseE2(fileString);

          this.dataSource = new MatTableDataSource<EcuParam>(items);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;

          this.countEcuParams.emit(items.length);
        })
      )
    })
  );

  constructor(private fileService: FileService,
    private fileParserService: FileParserService,
    private loggerService: TmtLoggerService) {
      // Subscribe to file data, since observables with Tegel are a pain. TODO: Replace with better alternative.
      this.fileData$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
    }

  ecuParams: EcuParam[];
  displayedColumns: string[] = ['EcuFamily', 'EcuName', 'Name', 'Description', 'Value', 'EnumValue', 'ValueType'];
  displayedColumnsForFiles: string[] = ['Name'];
  dataSource;
  dataSourceFiles;
  countEcuParamFiles: number;
  maxall = 10;
  baseUrl = environment.baseurl;
  environment = environment.environment
  TargetEnvironment = TargetEnvironment
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Output() countEcuParams: EventEmitter<any> = new EventEmitter();

  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.paginator && this.dataSource.paginator.length > this.maxall) {
      return [10, 20, this.dataSource.paginator.length];
    } else {
      return [10, 20];
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
