import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TestCase } from 'src/models/testCase';
import { MatAccordion } from '@angular/material/expansion';
import { TestCaseBaseService } from 'src/services/test-case.base.service';

@Component({
  selector: 'app-test-case',
  templateUrl: './test-case.component.html',
  styleUrls: ['./test-case.component.css']
})
export class TestCaseComponent implements OnInit {
  private subscription: Subscription;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  queryParamUid: string;
  queryParamVersion: string;
  testCase: TestCase;

  constructor(private testCaseBaseService: TestCaseBaseService,
    private route: ActivatedRoute,
    private router: Router) { 

    // force route reload whenever params change
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

  ngOnInit(): void {
    if (this.router.url.includes('testcasedetails')) {
      this.getParamUidFromUrlParameter();
      this.getParamVersionFromUrlParameter(); 
      this.getTestCase();
    } 
  }

  getTestCase(): void {
    if (this.queryParamVersion !== undefined) {
      this.subscription = this.testCaseBaseService.getTestCase(this.queryParamUid, +this.queryParamVersion).subscribe(tc => {
        this.testCase = tc;
      })
    }
    else {
      this.subscription = this.testCaseBaseService.getLatestTestCase(this.queryParamUid).subscribe(tc => {
        this.testCase = tc;
      })
    }
  }

  private getParamUidFromUrlParameter()
  {
    this.route.queryParams.subscribe(params => {
      this.queryParamUid = params['uid']; 
    });
  }

  private getParamVersionFromUrlParameter()
  {
    this.route.queryParams.subscribe(params => {
      this.queryParamVersion = params['version'];
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
