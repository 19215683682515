import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule, } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ApolloModule } from 'apollo-angular';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { GalleriaModule } from 'primeng/galleria';

import { HeaderComponent } from 'src/components/header/header.component';
import { AuthInterceptor } from 'src/middleware/auth.middleware';
import { AppConfigService } from 'src/services/app-config.service';
import { OffboardComponentBaseService } from 'src/services/offboard-component.base.service';
import { OffboardComponentGraphQLService } from 'src/services/offboard-component.graphql.service';
import { RequirementBaseService } from 'src/services/requirement.base.service';
import { RequirementGraphQLService } from 'src/services/requirement.graphql.service';
import { SopsBaseService } from 'src/services/sops.base.service';
import { SopsGraphQLService } from 'src/services/sops.graphql.service';
import { TestCaseBaseService } from 'src/services/test-case.base.service';
import { TestCaseGraphQLService } from 'src/services/test-case.graphql.service';
import { TestResultsBaseService } from 'src/services/test-results.base.service';
import { TestResultsGraphQLService } from 'src/services/test-results.graphql.service';
import { TestRunBaseService } from 'src/services/test-run.base.service';
import { TestRunGraphQLService } from 'src/services/test-run.graphql.service';
import { TestSpecificationBaseService } from 'src/services/test-specification.base.service';
import { TestSpecificationGraphQLService } from 'src/services/test-specification.graphql.service';
import { TestSuiteBaseService } from 'src/services/test-suite.base.service';
import { TestSuiteGraphQLService } from 'src/services/test-suite.graphql.service';
import { TroubleReportBaseService } from 'src/services/trouble-report.base.service';
import { TroubleReportGraphQLService } from 'src/services/trouble-report.graphql.service';
import { ErrorHandlerService, LOGGER_CONFIG } from 'tmt-logger';
import { JiraFeedbackComponent } from '../components/jira-feedback/jira-feedback.component';
import { StartPageModule } from '../modules/start-page/start-page.module';
import { TestResultsPageModule } from '../modules/test-results-page/test-results-page.module';
import { HighlightPipe } from '../pipes/highlight.pipe';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { TegelModule } from '@scania/tegel-angular';


@NgModule({
  declarations: [
    AppComponent,
    HighlightPipe,
    HeaderComponent,
    JiraFeedbackComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    StartPageModule,
    TestResultsPageModule,
    AppRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    NgxChartsModule,
    MatTabsModule,
    GalleriaModule,
    ApolloModule,
    TegelModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (appConfigService: AppConfigService) => () => appConfigService.load(),
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: LOGGER_CONFIG,
      useFactory: (appConfigService: AppConfigService) => appConfigService.logConfig$,
      deps: [AppConfigService]
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService
    },
    DatePipe,
    {
      provide: TestRunBaseService,
      useClass: TestRunGraphQLService
    },
    {
      provide: SopsBaseService,
      useClass: SopsGraphQLService
    },
    {
      provide: OffboardComponentBaseService,
      useClass: OffboardComponentGraphQLService
    },
    {
      provide: TestCaseBaseService,
      useClass: TestCaseGraphQLService
    },
    {
      provide: TestResultsBaseService,
      useClass: TestResultsGraphQLService
    },
    {
      provide: RequirementBaseService,
      useClass: RequirementGraphQLService
    },
    {
      provide: TroubleReportBaseService,
      useClass: TroubleReportGraphQLService
    },
    {
      provide: TestSuiteBaseService,
      useClass: TestSuiteGraphQLService
    },
    {
      provide: TestSpecificationBaseService,
      useClass: TestSpecificationGraphQLService
    },
  ],
  exports: [TegelModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
