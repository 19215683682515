export class Constants {
    // Input types
    public static InputType = 142;
    public static InputTypePassFail = 143;
    public static InputTypeRatingScale = 144;
    public static InputTypeYesNo = 153;
    public static InputTypeSingleSelection = 154;
    public static InputTypeMultipleSelection = 155;
    public static InputTypeText = 156;
    public static InputTypeOrderSelection = 157;
    public static InputTypeNumeric = 171;

}
