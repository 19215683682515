<div class="row left-margin">
  <div class="col-md-12">
    <h4>Valid Fenix Test data 3 months back from current time: {{dateTime | date: 'yyyy-MM-dd HH:mm:ss'}}</h4>
  </div>
    <div class="col-md-12"> 
      <mat-form-field>
        <mat-label>Owner</mat-label>
        <select matNativeControl (change)="updateStatsForGroup($event.target.value)">
          <option *ngFor="let group of orgsList" value={{group.value}}>
            {{group.display}}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Test Level</mat-label>
        <select matNativeControl (change)="updateStatsForTestLevel($event.target.value)">
          <option *ngFor="let testLevel of testLevels" value={{testLevel.Id}}>
            {{testLevel.Name}}
          </option>
        </select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Tested by Group</mat-label>
        <select matNativeControl (change)="updateStatsForTestedBy($event.target.value)">
          <option *ngFor="let group of orgsList" value={{group.value}}>
            {{group.display}}
          </option>
        </select>
      </mat-form-field>
    </div>

  <div class="row">
    <div id="divTestAnalysesStatistics" class="col-md-11">
      <mat-accordion multi>
        <mat-expansion-panel [expanded]="true" class="bottom-margin">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Test Analyses statistics
            </mat-panel-title>
          </mat-expansion-panel-header> 
          <div class="bottom-margin">
            <ngx-charts-line-chart [view]="view" [scheme]="colorScheme" [legend]="true" [legendTitle]="''" [legendPosition]="'right'"
              [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
              [xAxis]="true" [yAxis]="true" [results]="overviewData" (select)="onSelect($event)"
              (activate)="onActivate($event)" (deactivate)="onDeactivate($event)" [yAxisTickFormatting]="axisFormatToInteger">
            </ngx-charts-line-chart>
            <p class="small-font align-right"><i>* Number of unique items per week, ignoring versions</i></p>
            <p class="small-font align-right"><i>** Minimum one approval from a reviewer, ignoring versions</i></p>
          </div>
          
          <br />
          <div class="col-md-11" style="margin-top:50px;">
            <table class="table-overview" *ngFor="let ov of overviewData; first as isFirst">
              <tr *ngIf="isFirst">
                <th width="20%">Week</th>
                <th *ngFor="let i of ov.series">{{i.name}}</th>
              </tr>
              <tr>
                <td width="20%">{{ov.name}}</td>
                <td *ngFor="let i of ov.series">{{i.value}}</td>
              </tr>
            </table>
            <br />
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>


</div>