import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseAPIService } from './base-api.service';
import { environment } from '../environments/environment';
import { RequirementWithTestAnalysis } from 'src/models/requirement';

@Injectable({
  providedIn: 'root'
})
export class RequirementService extends BaseAPIService{

  constructor(private http: HttpClient) {
    super();
   }

   getRequirementsByTestCase(testCaseUid: string, testCaseVersion: number): Observable<Array<RequirementWithTestAnalysis>> {
    const headers = new HttpHeaders()
    .set('X-CustomHeader', String(new Date().getTimezoneOffset())); 
    return this.http.get<Array<RequirementWithTestAnalysis>>(`${environment.baseurl}requirements/${testCaseUid}/${testCaseVersion}`, {withCredentials: true, headers})
    .pipe(catchError(this.handleError));
  }

  getRequirementsByResult(testResultUid: string): Observable<Array<RequirementWithTestAnalysis>> {
    const headers = new HttpHeaders()
    .set('X-CustomHeader', String(new Date().getTimezoneOffset())); 
    return this.http.get<Array<RequirementWithTestAnalysis>>(`${environment.baseurl}requirements/ByResult/${testResultUid}`, {withCredentials: true, headers})
    .pipe(catchError(this.handleError));
  }

}
