import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { BaseAPIService } from './base-api.service';
import { TestLevel } from '../models/testLevel';

@Injectable({
  providedIn: 'root'
})
export class TypesService extends BaseAPIService{

  constructor(private http: HttpClient) {
    super();
   }

  getAllTestLevels(): Observable<Array<TestLevel>> {
    return this.http.get<Array<TestLevel>>(`${environment.baseurl}testlevels`, {withCredentials: true})
      .pipe(catchError(this.handleError));  
  }
  
}
