<div class="col-md-12" [ngClass]="{'loader' : gettingTestSuites}"></div>
<div class="col-md-12" *ngIf="testSuites?.length > 0 && !gettingTestSuites">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef >
          <span [style.paddingLeft.px]="40"> Name </span>
        </th>
        <td mat-cell *matCellDef="let data" >
          <button mat-icon-button
                  class="expand-button align-top"
                  [style.visibility]="!data.expandable ? 'hidden' : ''"
                  [style.marginLeft.px]="data.level * 20"
                  (click)="treeControl.toggle(data)">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(data) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <span class="align-top bold">{{data.name}}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="Version">
        <th mat-header-cell *matHeaderCellDef width="5%"> Version </th>
        <td mat-cell *matCellDef="let data"> {{data.version}} </td>
      </ng-container>
      <ng-container matColumnDef="RegTime">
        <th mat-header-cell *matHeaderCellDef width="10%"> Creation time  </th>
        <td mat-cell *matCellDef="let data"> {{data.regTime  | date: 'yyyy-MM-dd HH:mm:ss'}} </td>
      </ng-container>
      <ng-container matColumnDef="RegBy">
        <th mat-header-cell *matHeaderCellDef width="10%"> Created by </th>
        <td mat-cell *matCellDef="let data"> {{data.regBy}} </td>
      </ng-container>
      <ng-container matColumnDef="TestSuiteUid">
        <th mat-header-cell *matHeaderCellDef width="20%"> Test suite UID </th>
        <td mat-cell *matCellDef="let data"> {{data.testSuiteUid}} </td>
      </ng-container>
      <ng-container  matColumnDef="GoTo">
          <th mat-header-cell *matHeaderCellDef  width="20%">Go to</th>
          <td mat-cell *matCellDef="let data" (click)="navigateTo(data)" class="scania-link">link to test suite details page</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
      (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" ></tr>
    </table>
    
  </div>
  
  <div *ngIf="testSuites?.length === 0"><span class="scania-icon-info xs"></span><span class="bold">No included test suites found or you are not authorized to view them</span></div>
  
