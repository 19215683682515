
import { Observable } from "rxjs";
import { BaseAPIService } from "./base-api.service";
import { Injectable } from "@angular/core";
import { TestSpecification } from "src/models/testSpecification";

@Injectable({ providedIn: 'root' })
export abstract class TestSpecificationBaseService extends BaseAPIService {

    public abstract getLatestTestSpecification(uid: string): Observable<TestSpecification>
    public abstract getTestSpecification(uid: string, testSpecificationVersion: number): Observable<TestSpecification>
    public abstract getTestSpecificationDetails(testSpecificationUid: string, testSpecificationVersion: number): Observable<TestSpecification>
    public abstract getLatestTestSpecificationDetails(testSpecificationUid: string): Observable<TestSpecification>
}