import { TestAnalysis } from "./testAnalysis";
import { Version } from "./version";

export class TestSpecification {
    public UID: string;
    public ItemNo: string;
    public Name: String;
    public Version: number;
    public Owners: string[];
    public TestAnalyses: TestAnalysis[];
    public ApprovalStatus: number;
    public RegTime: Date;
    public RegBy: string;
    public Versions: Version;
}