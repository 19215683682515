import { TroubleReport } from './troubleReport';
import { Classification, ClassificationReason } from 'src/enums/classification';
import { ECU } from './ecu';
import { OffboardComponent } from './offboardComponent';
import { TestCaseAlternativeFailedReason } from './testCaseAlternativeFailedReason';

export class TestResult {
    TestResultUid: string;
    VehicleName: string;
    VINNo: string;
    ExecutionTimeTestRun: Date;
    ExecutionTimeTestResult: Date;
    ExecutedBy: string;
    Result: string;
    ResultComment: string;
    Classification: Classification;
    ClassificationReason: ClassificationReason;
    ClassificationComment: string;
    TestScriptUid: string;
    TestScriptName: string;
    TestScriptVersion: string;
    UC: string;
    UF: string;
    SCN: string;
    FailedReason: number;
    FailedReasonText: string;
    FailedBy: string;
    FailedReasonComment: string;
    ExecutionLocation: string;
    InputType: number;
    hide: boolean;
    troubleReports: TroubleReport[];
    ResultText: string;
    ErrorText: string;
    ecus: ECU[];
    offboardComponents: OffboardComponent[];
    testCaseAlternativeFailedReasons: TestCaseAlternativeFailedReason[];
    PositiveComment: string;
    NegativeComment: string;

    // test result linked to
    TestRunUid: string;
    TestRunType: number;
    TestCaseUid: string;
    TestCaseName: string;
    TestCaseVersion: number;
    TestSuiteUid: string;
    TestSuiteName: string;
    TestSuiteVersion: string;
    MainTestSuiteUid: string;
    MainTestSuiteName: string;
    MainTestSuiteVersion: string;
    TestSpecificationUid: string;
    TestSpecificationName: string;
    TestSpecificationVersion: string;
    TestSpecificationItemNo: string;
    LinkUid: string;
}

export class TestResultBase {
    TestResultUid: string;
    ExecutionTimeTestResult: Date;
    TestRunUid: string;
    ExecutionTimeTestRun: Date;
    Result: string;
    TestCaseUid: string;
    TestCaseVersion: number;
    TestCaseName: string;
}
