<div class="row left-margin right-margin">
    <div class="col-md-12" *ngIf="testCase?.TCUID !== '00000000-0000-0000-0000-000000000000'">
        <div id="divTestRunHeading" class="col-md-12 no-padding bottom-margin">
            <div class="results-heading">
                <h2>Details page for test case: {{testCase?.Name}}</h2>     
            </div>
            <div class="results-heading-details">
                &nbsp;(version {{testCase?.Version}}) - {{testCase?.RegTime | date: 'yyyy-MM-dd HH:mm:ss'}} -
                {{testCase?.RegBy | uppercase}}&nbsp;
                <span [routerLink]="['/testcase/', testCase?.TCUID]" class="scania-link small-font">[View all results for this test case]</span>
            </div>   
        </div>
        <div>
            <button mat-button (click)="accordion.openAll()" class="btn btn-sm btn-primary">Expand all panels</button>
            <button mat-button (click)="accordion.closeAll()" class="btn btn-sm btn-default">Collapse all panels</button>
        </div>
        <mat-accordion multi>
            <div class="bottom-margin-small">
                <mat-expansion-panel [expanded]="true" class="bottom-margin">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Steps
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <!-- <div id="divTestCaseSteps" *ngIf="queryParamUid && queryParamVersion"> -->
                    <div id="divTestCaseSteps" *ngIf="queryParamUid">
                        <app-test-case-steps [caseUid]='queryParamUid' [caseVersion]='queryParamVersion'></app-test-case-steps>
                    </div>
                    <!-- <div id="divTestCaseDetails" *ngIf="queryParamUid && queryParamVersion === undefined">
                        <app-test-case-steps [caseUid]='queryParamUid'></app-test-case-steps>
                    </div> -->
                </mat-expansion-panel>
            </div>
            <div class="bottom-margin-small">
                <mat-expansion-panel [expanded]="true" class="bottom-margin">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Details
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div id="divTestCaseDetails" *ngIf="queryParamUid">
                        <app-test-case-details [testCaseUid]='queryParamUid' [testCaseVersion]='queryParamVersion' [tcversionchangeable]='true'></app-test-case-details>
                    </div>
                </mat-expansion-panel>
            </div>   
            <div class="bottom-margin-small">
                <mat-expansion-panel [expanded]="true" class="bottom-margin">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Test case alternatives &nbsp;&nbsp;
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div id="divAlternativesForTestCase" *ngIf="queryParamUid">
                        <app-test-case-alternative [testCaseUid]='queryParamUid' [testCaseVersion]='queryParamVersion'>
                        </app-test-case-alternative>
                    </div>
                </mat-expansion-panel>
            </div>   
        </mat-accordion>
    </div>

    <div *ngIf="testCase ?.TCUID === '00000000-0000-0000-0000-000000000000'">
       <span class="scania-icon-info xs"></span><span class="bold">Test case not found or you are not authorized to view it</span>
    </div>
</div>