import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseAPIService } from './base-api.service';
import { TroubleReport } from '../models/troubleReport';

@Injectable({ providedIn: 'root'})

export abstract class TroubleReportBaseService extends BaseAPIService {
  public abstract getTroubleReports(resultUid: string): Observable<Array<TroubleReport>> 
}
