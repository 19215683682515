export enum Classification {
    Valid,
    NotValid
}

export enum ClassificationReason {
    OK,
    Environment,
    Script
}

