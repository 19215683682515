import { Component, OnInit, Type, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { take } from 'rxjs/operators';
import { filteredStats } from 'src/models/filteredStats';
import { OverviewItem, OverViewSeries } from 'src/models/overview';
import { TestLevel } from 'src/models/testLevel';
import { DropDownType } from 'src/modules/start-page/components/filter/filter.component';
import { OverviewService } from 'src/services/overview.service';
import { TypesService } from 'src/services/types.service';
import { UsersAndGroupsService } from 'src/services/users-and-groups.service';
import { TmtLoggerService } from 'tmt-logger';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class OverviewComponent implements OnInit {

  view: any[] = [1300, 400];
  totalResultsDistributedOnInputType: OverviewItem[];
  overviewData: OverViewSeries[];
  sumCreatedTA: number;
  xAxisLabel: string = 'Week';
  yAxisLabel: string = 'Number of items';
  dateTime: Date;
  testLevels: TestLevel[];
  orgsList: DropDownType[] = [{ display: 'All', value: 'All' },];

  SelectedFilters: filteredStats;
  colorScheme = {
    domain: ['#16417F', '#D6001C', '#CEB888', '#FA6E23', '#438151', '#82848A', '#A6D1AD']
  };

  constructor(
    private overviewService: OverviewService,
    private usersAndGroupsService: UsersAndGroupsService,
    private typesService: TypesService,
    private loggerService: TmtLoggerService) { }

  ngOnInit(): void {
    this.getOverviewData();
    this.dateTime = new Date()
    this.getAllTestLevels();

    //settings should be set to ALL for everyone as init.
    this.SelectedFilters = { testGroup: 'All', testLevel: -1, groupOwner: 'All' };
    this.usersAndGroupsService.getAllUsers().pipe(take(1)).subscribe(users => {
      //Filter out the organizations so they are unique and only 4 letter organizations
      let orgs = users.map(usr => usr.organization).filter(s => s.length === 4).filter((v, i, a) => a.indexOf(v) === i).sort();
      let orgsObjects = orgs.map(gr => ({ display: gr, value: gr }));
      this.orgsList = this.orgsList.concat(orgsObjects);
    })
  }

  getTotalResultsDistributedOnInputType() {
    this.overviewService.getTotalResultsDistributedOnInputType()
      .subscribe(data => {
        this.totalResultsDistributedOnInputType = data;
      },
        error => this.loggerService.logError(error)
      );
  }

  getOverviewData() {
    this.overviewService.getOverviewData()
      .subscribe(data => {
        this.overviewData = data;
      },
        error => this.loggerService.logError(error)
      );
  }

  getAllTestLevels() {
    this.typesService.getAllTestLevels()
      .subscribe(data => {
        this.testLevels = [];
        this.testLevels.push({ Id: -1, Name: 'All' });
        this.testLevels = this.testLevels.concat(data);
      },
        error => this.loggerService.logError(error)
      );
  }

  onSelect(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  updateStatsForGroup(event: any) {
    this.SelectedFilters = { ...this.SelectedFilters, groupOwner: event }
    this.getFilteredData();
    //console.log(this.SelectedFilters);
  }

  updateStatsForTestLevel(event: any) {
    this.SelectedFilters = { ...this.SelectedFilters, testLevel: event }
    this.getFilteredData();
    //console.log(this.SelectedFilters);
  }

  updateStatsForTestedBy(event: any) {
    this.SelectedFilters = { ...this.SelectedFilters, testGroup: event }
    this.getFilteredData();
    //console.log(this.SelectedFilters);
  };

  getFilteredData() {
    this.overviewService.getOverviewDataWithFilter(this.SelectedFilters).subscribe(data => {
      this.overviewData = data;
    });
  }

  axisFormatToInteger(val) {
    if (val % 1 === 0) {
      return val.toLocaleString();
    } else {
      return '';
    }
  }

}
