<div class="row left-margin right-margin" >
  <div class="col-md-12" >
    <div class="col-md-12" [ngClass]="{'loader' : gettingTestRuns}"></div>
    <div *ngIf="this.testCases?.length > 0" > 
      <div class="col-md-10" >        
        <h2> Results for test case: {{this.testCases[0].TestRun.TestResults[0].TestCaseName}}&nbsp;
        <!-- commented out, if user has chosen specific version this link goes to latest which is confusing, better that user uses the usual back button to get
          back to the version he/she came from  
           <span [routerLink]="['/testcasedetails']" 
          [queryParams]="{uid: this.testCases[0].TestRun.TestResults[0].TestCaseUid, version:this.testCases[0].TestRun.TestResults[0].TestCaseVersion}" class="scania-link small-font">
          [View test case details]</span>-->
        </h2>
      </div>
      <div class="col-md-2 align-right no-padding">
        <div class="col-md-12 no-padding">
          <input autofocus type="text" id="txtSearchTestCase" class="form-control" placeholder="Filter..."
            (keyup)="applyFilter($event)" />
        </div>
      </div>  
      <div class="col-md-12 mat-elevation-z0" >
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="ItemNo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ItemNo</th>
             <td mat-cell *matCellDef="let element" >
              <span *ngIf="element.TestRunType === 410; else elseBlockItemNo">
                          {{element.TestSpecification.ItemNo}}
              </span>
              <ng-template #elseBlockItemNo>
                  -
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name</th>
            <td mat-cell *matCellDef="let element" >
              <span *ngIf="element.TestRunType === 410; else elseBlockName">
                          {{element.TestSpecification.Name}}
              </span>
              <ng-template #elseBlockName>
                  {{element.TestSuite.Name}}
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="Type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type</th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.TestRunType === 410; else elseBlockType">
                  Test specification
              </span>
              <ng-template #elseBlockType>
                  Test suite
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="TestRun.Environment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Environment</th>
            <td mat-cell *matCellDef="let element"> {{element.TestRun.Environment}} </td>
          </ng-container>
          <ng-container matColumnDef="TestRun.TestResults[0].TestCaseVersion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Test case version </th>
            <td mat-cell *matCellDef="let element"> {{element.TestRun.TestResults[0].TestCaseVersion}} </td>
          </ng-container>
          <ng-container matColumnDef="TestRun.TestResults[0].Result">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="10%"> Result </th>
            <td mat-cell *matCellDef="let element"> {{element.TestRun.TestResults[0].Result}} </td>
          </ng-container>
          <ng-container matColumnDef="TroubleReports">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Trouble reports </th>
            <td mat-cell *matCellDef="let element">
              <i *ngIf="element.TestRun.TestResults[0].troubleReports.length > 0" class="scania-icon-trouble-report xs" title="Trouble reports exist"></i>
            </td>
          </ng-container>
          <ng-container matColumnDef="TestRun.TestResults[0].ResultComment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header width="20%"> Result comment</th>
            <td mat-cell *matCellDef="let element"> 
              {{element.TestRun.TestResults[0].ResultComment}} 
              <div *ngIf="element.TestRun.TestResults[0].PositiveComment !== '' && element.TestRun.TestResults[0].PositiveComment !== null || 
                          element.TestRun.TestResults[0].NegativeComment !== '' && element.TestRun.TestResults[0].NegativeComment !== null">
                <table class="table-condensed no-margin">
                  <tbody>
                    <tr *ngIf="element.TestRun.TestResults[0].PositiveComment !== '' && element.TestRun.TestResults[0].PositiveComment !== null">
                      <td class="no-padding" title="Positive comment"><span class="glyphicon glyphicon-ok-sign text-success"></span> {{element.TestRun.TestResults[0].PositiveComment}}</td>
                    </tr>
                    <tr *ngIf="element.TestRun.TestResults[0].NegativeComment !== '' && element.TestRun.TestResults[0].NegativeComment !== null">
                      <td class="no-padding" title="Negative comment"><span class="glyphicon glyphicon-remove-sign text-danger"></span> {{element.TestRun.TestResults[0].NegativeComment}}</td>
                    </tr>
                  </tbody>
                </table>
              </div></td>
          </ng-container>
          <ng-container matColumnDef="TestRun.ExecutionTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Execution time </th>
            <td mat-cell *matCellDef="let element"> {{element.TestRun.ExecutionTime | date: 'yyyy-MM-dd HH:mm:ss'}} </td>
          </ng-container>
          <ng-container matColumnDef="TestRun.RegBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Executed by </th>
            <td mat-cell *matCellDef="let element"> {{element.TestRun.RegBy}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'failed':row.TestRun.TestResults[0].Result == 'failed', 'passed':row.TestRun.TestResults[0].Result == 'passed', hovered: row.hovered}"
            (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="navigateTo(row)"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="getPageSizeOptions()" showFirstLastButtons></mat-paginator>
      </div>
    </div>
    <div *ngIf="this.testCases?.length == 0">
      <span class="scania-icon-info xs"></span><span class="bold">There are no results for this test case or you are not authorized to view them</span>
        <!-- <h4>There are no results for this test cae: {{this.testCaseNoResults.Name}}&nbsp;   -->
        <!-- <span [routerLink]="['/testcasedetails']" 
        [queryParams]="{uid: this.testCaseNoResults.TCUID, version:this.testCaseNoResults.Version}" class="scania-link small-font">
        [View test case details]</span> -->   
    </div>
  </div>
</div> 