import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { BaseAPIService } from './base-api.service';
import { File } from '../models/file';

@Injectable({
  providedIn: 'root'
})

export class DownloadService extends BaseAPIService {

  constructor(private http: HttpClient) {
    super();
  }

  getReadoutFiles(runUid: string): Observable<Array<File>> {
    return this.http.get<Array<File>>(`${environment.baseurl}testrun/${runUid}/download/readouts/`, {withCredentials: true})
      .pipe(catchError(this.handleError));
  }

  getEcuParamsFiles(runUid: string): Observable<Array<File>> {
    return this.http.get<Array<File>>(`${environment.baseurl}testrun/${runUid}/download/e2/`, {withCredentials: true})
      .pipe(catchError(this.handleError));
  }  

  getAttachmentsByTestRun(runUid: string): Observable<Array<File>> {
    const headers = new HttpHeaders()
      .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<Array<File>>(`${environment.baseurl}testrun/${runUid}/download/attachments/`, {withCredentials: true, headers})
    .pipe(catchError(this.handleError));
  }

  getAttachmentsByTestResult(resultUid: string): Observable<Array<File>> {
    const headers = new HttpHeaders()
      .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<Array<File>>(`${environment.baseurl}testresult/${resultUid}/download/attachments/`, {withCredentials: true, headers})
    .pipe(catchError(this.handleError));
  }

  getImagesByTestResult(resultUid: string): Observable<Array<File>> {
    return this.http.get<Array<File>>(`${environment.baseurl}testresult/${resultUid}/download/images/`, {withCredentials: true})
    .pipe(catchError(this.handleError));
  }  

  getOffboardComponentFiles(runUid: string): Observable<Array<File>> {
    const headers = new HttpHeaders()
    .set('X-CustomHeader', String(new Date().getTimezoneOffset()));
    return this.http.get<Array<File>>(`${environment.baseurl}testrun/${runUid}/download/offboardcomponents/`, {withCredentials: true, headers})
      .pipe(catchError(this.handleError));
  }

}