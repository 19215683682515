import { Component, OnInit, OnDestroy } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TmtLoggerService } from 'tmt-logger';
import { AuthService } from 'src/services/auth.service';
import { TargetEnvironment } from 'src/environments/environment.interfaces';
import { map, Subscription } from 'rxjs';
import { LaunchDarklyService } from 'src/services/launchdarkly.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // JiraIssueKey: string;
  fullEnvString = ''
  loggedInUser: string;
  navLinks: any[];
  activeLinkIndex = 0;
  background: ThemePalette = undefined;
  environment = environment.environment
  TargetEnvironment = TargetEnvironment
  private subscription: Subscription = new Subscription();

  public userName$ = this.authService.userData$.pipe(map((r) => r.userName))
  public companyName$ = this.authService.userData$.pipe(map((r) => r.companyName))

  constructor(
    // private dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private loggerService: TmtLoggerService,
    private ldService: LaunchDarklyService) {
      // TODO Commented out menus for now, wait for decision if they are to be implemented for Stockholm.
      // if (environment.environment == TargetEnvironment.Hero) {
      this.navLinks = [];
      this.updateFullEnvString();
  }

  private updateFullEnvString() {
    this.subscription.add(
      this.ldService.launchDarklyFlags$.subscribe(flags => {
        const staging = flags['staging'];
        console.log('Staging flag value:', staging);
        console.log(flags)
        const logConfig = environment.logConfig.env;
        const stagingString = staging ? ' staging ' : ' release';
        if (logConfig === 'dev' || staging) {
          this.fullEnvString = " - " + logConfig + stagingString;
        }
      })
    );
  }

  ngOnInit() {
    this.activeLinkIndex = 0;
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(this.navLinks.find(tab => tab.link === '.' + this.router.url));
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
