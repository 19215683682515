
import { Observable } from "rxjs";
import { BaseAPIService } from "./base-api.service";
import { Injectable } from "@angular/core";
import { Step } from "src/models/step";
import { TestCase, TestCaseAlternative } from "src/models/testCase";

@Injectable({ providedIn: 'root' })
export abstract class TestCaseBaseService extends BaseAPIService {
    public abstract getTestCaseSteps(testCaseUid: string, testCaseVersion: number): Observable<Step[]>
    public abstract getLatestTestCaseSteps(testCaseUid: string): Observable<Array<Step>>
    public abstract getAlternativesForTestCase(testCaseUid: string, testCaseVersion: number): Observable<Array<TestCaseAlternative>>
    public abstract getAlternativesForLatestTestCase(testCaseUid: string): Observable<Array<TestCaseAlternative>>
    public abstract getTestCaseDetails(testCaseUid: string, testCaseVersion: number): Observable<TestCase> 
    public abstract getLatestTestCaseDetails(testCaseUid: string): Observable<TestCase>
    public abstract getLatestTestCase(testCaseUid: string): Observable<TestCase> 
    public abstract getTestCase(testCaseUid: string, testCaseVersion: number): Observable<TestCase> 
}