
import { Observable } from "rxjs";
import { TestRunStatistics, TestRunViewModel } from "src/models/testRun";
import { BaseAPIService } from "./base-api.service";
import { Injectable } from "@angular/core";
import { Property } from "src/models/property";

@Injectable({ providedIn: 'root' })
export abstract class TestRunBaseService extends BaseAPIService {

    public abstract getTestRunsByDate(startDate: string, endDate: string): Observable<Array<TestRunViewModel>>;
    public abstract getTestRunStatistics(uid: string): Observable<TestRunStatistics>;
    public abstract getTestRun(uid: string): Observable<TestRunViewModel>
    public abstract getTestRunProperties(uid: string): Observable<Array<Property>>
    public abstract getTestRunsByTestCaseUid(uid: string): Observable<TestRunViewModel[]>
    public abstract getTestRunsByTestSuiteUid(uid: string): Observable<Array<TestRunViewModel>> 
    public abstract getTestRunsByTestSpecificationUid(uid: string): Observable<Array<TestRunViewModel>> 
}