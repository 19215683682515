import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {TestResultsPageRoutingModule} from './test-results-page-routing.module';
import { TreeMenuComponent } from './components/tree-menu/tree-menu.component';
import { TestResultsComponent } from './components/test-results/test-results.component';
import { TestRunComponent } from './components/test-run/test-run.component';
import { TestCaseStepsComponent } from './components/test-case-steps/test-case-steps.component';
import { TroubleReportsComponent } from './components/trouble-reports/trouble-reports.component';
import { EcuReadoutComponent } from './components/ecu-readout/ecu-readout.component';
import { HistoryComponent } from './components/history/history.component';
import { EcuParamComponent } from './components/ecu-param/ecu-param.component';
import { TestCaseDetailsComponent } from './components/test-case-details/test-case-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import { MatDialogModule } from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTreeModule} from '@angular/material/tree';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCardModule} from '@angular/material/card';
import { TestResultsPageComponent } from './test-results-page.component';
import { TestCasesComponent } from './components/test-cases/test-cases.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { TestResultPropertiesComponent } from './components/test-result-properties/test-result-properties.component';
import { SopsComponent } from './components/sops/sops.component';
import { OverviewComponent } from './components/overview/overview.component';
import { NgxChartsModule }from '@swimlane/ngx-charts';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { ImagesComponent } from './components/images/images.component';
import {GalleriaModule} from 'primeng/galleria';
import { DialogComponent } from './components/dialog/dialog.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { TestRunPropertiesComponent } from './components/test-run-properties/test-run-properties.component';
import { EnvironmentComponent } from './components/environment/environment.component';
import { TestCaseComponent } from './components/test-case/test-case.component';
import { TestSuiteComponent } from './components/test-suite/test-suite.component';
import { TestSuitesComponent } from './components/test-suites/test-suites.component';
import { TestSuiteDetailsComponent } from './components/test-suite-details/test-suite-details.component';
import { TestSuiteTestcasesComponent } from './components/test-suite-testcases/test-suite-testcases.component';
import { TestSuiteAlternativeComponent } from './components/test-suite-alternative/test-suite-alternative.component';
import { TestSuiteTestsuitesComponent } from './components/test-suite-testsuites/test-suite-testsuites.component';
import { FormsModule } from '@angular/forms';
import { TestCaseAlternativeComponent } from './components/test-case-alternative/test-case-alternative.component';
import { RequirementsComponent } from './components/requirements/requirements.component';
import { OffboardComponentComponent } from './components/offboard-component/offboard-component.component';
import { RequirementFulfillmentComponent } from './components/requirement-fulfillment/requirement-fulfillment.component';
import { ToastModule } from 'primeng/toast';
import { MultiSelectModule } from 'primeng/multiselect';
import { TestSpecificationsComponent } from './components/test-specifications/test-specifications.component';
import { TestSpecificationComponent } from './components/test-specification/test-specification.component';
import { TestSpecificationDetailsComponent } from './components/test-specification-details/test-specification-details.component'

@NgModule({
  declarations: [
    TreeMenuComponent,
    TestResultsComponent,
    TestRunComponent,
    TestCaseStepsComponent,
    TroubleReportsComponent,
    EcuReadoutComponent,
    HistoryComponent,
    EcuParamComponent,
    TestCaseDetailsComponent,
    TestResultsPageComponent,
    TestCasesComponent,
    TestSuiteComponent,
    TestResultPropertiesComponent,
    SopsComponent,
    OverviewComponent,
    AttachmentsComponent,
    ImagesComponent,
    DialogComponent,
    TestRunPropertiesComponent,
    EnvironmentComponent,
    TestCaseComponent,
    TestSuitesComponent,
    TestSuiteDetailsComponent,
    TestSuiteTestcasesComponent,
    TestSuiteAlternativeComponent,
    TestSuiteTestsuitesComponent,
    TestCaseAlternativeComponent,
    RequirementsComponent,
    OffboardComponentComponent,
    RequirementFulfillmentComponent,
    TestSpecificationsComponent,
    TestSpecificationComponent,
    TestSpecificationDetailsComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatTableModule,
    TestResultsPageRoutingModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatIconModule,
    MatExpansionModule,
    MatSidenavModule,
    MatListModule,
    MatTreeModule,
    MatCardModule,
    MatCheckboxModule,
    NgxChartsModule,
    GalleriaModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ToastModule,
    MultiSelectModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [TestResultsPageComponent]
})
export class TestResultsPageModule { }
