import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { RESULT_PROPERTIES_QUERY, TEST_RESULT_QUERY } from "src/graphql/queries/abstraction-layer-queries";
import { Property } from "src/models/property";
import { TestResult } from "src/models/testResult";
import { ApolloService } from "./apollo.service";
import { TestResultsBaseService } from "./test-results.base.service";


@Injectable({ providedIn: 'root' })
export class TestResultsGraphQLService extends TestResultsBaseService {
  constructor(private apolloService: ApolloService) { super(); }

  getTestResult(uid: string): Observable<TestResult> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsTestResult: TestResult }>({
          query: TEST_RESULT_QUERY,
          variables: { Uid: uid }
        })
      }),
      map(result => {
        if (result.data && result.data.resultsTestResult) {
          return result.data.resultsTestResult

        } else {
          return new TestResult()
        }
      }),
      shareReplay(1)
    );
  }

  getTestResultProperties(uid: string): Observable<Property[]> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsPropertiesForTestResult: Property[] }>({
          query: RESULT_PROPERTIES_QUERY,
          variables: { TestResultUid: uid }
        })
      }),
      map(result => {
        if (result.data && result.data.resultsPropertiesForTestResult) {
          return result.data.resultsPropertiesForTestResult

        } else {
          return <Property[]>[];
        }
      }),
      shareReplay(1)
    );
  }
}
