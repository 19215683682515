<div class="row left-margin right-margin">
    <div class="col-md-12">
      <div *ngIf="this.testSuites?.length > 0">
        <div class="col-md-10">
          <h2>Test runs for test suite: {{this.activeTestSuite?.Name}}&nbsp;</h2>
          <span class="small-font-italic">Select test run to view test results</span>
        </div>
        <div class="col-md-2 align-right no-padding">
          <div class="col-md-12 no-padding">
            <input autofocus type="text" id="txtSearchTestSuite" class="form-control" placeholder="Filter..."
              (keyup)="applyFilter($event)" />
          </div>
        </div>
        <div class="col-md-12 mat-elevation-z0" >
          <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="TestRun.TestRunUid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header width="15%"> Test run UID</th>
                <td mat-cell *matCellDef="let element"> {{element.TestRun?.TestRunUid}} </td>
              </ng-container>
            <ng-container matColumnDef="TestRun.Environment">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Environment</th>
              <td mat-cell *matCellDef="let element"> {{element.TestRun?.Environment}} </td>
            </ng-container>
            <ng-container matColumnDef="TestSuiteVersion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Test suite version</th>
                <td mat-cell *matCellDef="let element"> {{element.TestSuiteVersion}} </td>
              </ng-container>
            <ng-container matColumnDef="TestRun.ExecutionTime">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Execution time </th>
              <td mat-cell *matCellDef="let element"> {{element.TestRun?.ExecutionTime | date: 'yyyy-MM-dd HH:mm:ss'}} </td>
            </ng-container>
            <ng-container matColumnDef="TestRun.RegBy">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Executed by </th>
              <td mat-cell *matCellDef="let element"> {{element.TestRun?.RegBy}} </td>
            </ng-container>
             <ng-container matColumnDef="TestSuite.Name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Main test suite </th>
                <td mat-cell *matCellDef="let element"> {{element.TestSuite?.Name == this.activeTestSuite?.Name ? '-' : element.TestSuite?.Name}} </td>
            </ng-container>
            <ng-container matColumnDef="TestSuite.Version">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Main test suite version </th>
              <td mat-cell *matCellDef="let element"> {{element.TestSuite?.Name == this.activeTestSuite?.Name ? '-' : element.TestSuite?.Version}} </td>
          </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{hovered: row.hovered}"
              (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" (click)="navigateTo(row)"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="getPageSizeOptions()" showFirstLastButtons></mat-paginator>
        </div>
      </div>    
    </div>
    <div *ngIf="this.testSuites?.length == 0">
      <h4>There are no test runs for this test suite.</h4>
    </div>
  </div>

