import { Component, ViewChild, Input, OnChanges, SimpleChanges, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { TestRunViewModel } from 'src/models/testRun';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatAccordion } from '@angular/material/expansion';
import { MatTableDataSource } from '@angular/material/table';
import { TestResultsTableService } from '../../services/test-results-table-service';

@Component({
  selector: 'app-start-page-table',
  templateUrl: './start-page-table.component.html',
  styleUrls: ['./start-page-table.component.css']
})
export class StartPageTableComponent implements OnChanges, OnInit {

  displayedColumns: string[] = ['ItemNo', 'Name', 'Type', 'TestRun.Environment', 'TestRun.ExecutionTime', 'TestRun.RegBy', 'TestRun.TestRunUid'];

  dataSource;
  maxall = 10;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  @Input()
  filteredTable: TestRunViewModel[];
  @Input() StartDate: string;
  @Input() EndDate: string;
  @Input() PropertyName: string;
  @Input() PropertyValue: string;
  getTestRunByDateRunning: boolean;

  constructor(private router: Router, private testResultsTableService: TestResultsTableService) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<TestRunViewModel>();
    this.testResultsTableService.isLoadingTable.subscribe(isLoading => {
      this.getTestRunByDateRunning = isLoading
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filteredTable) {
      const dataSource = new MatTableDataSource<TestRunViewModel>(this.filteredTable);
      dataSource.paginator = this.paginator;
      // set a new sortingDataAccessor to be able to sort nested objects
      dataSource.sortingDataAccessor = (item, property) => {
        if (item.TestRunType === 410) {
          switch (property) {
            case 'ItemNo': return item.TestSpecification.ItemNo;
            case 'Name': return item.TestSpecification.Name;
            case 'Type': return "Test specification";
            case 'TestRun.Environment': return item.TestRun.Environment;
            case 'TestRun.ExecutionTime': return item.TestRun.ExecutionTime;
            case 'TestRun.RegBy': return item.TestRun.RegBy;
            case 'TestRun.TestRunUid': return item.TestRun.TestRunUid;
            default: return item[property];
          }
        }
        else {
          switch (property) {
            case 'Name': return item.TestSuite.Name;
            case 'Type': return "Test suite";
            case 'TestRun.Environment': return item.TestRun.Environment;
            case 'TestRun.ExecutionTime': return item.TestRun.ExecutionTime;
            case 'TestRun.RegBy': return item.TestRun.RegBy;
            case 'TestRun.TestRunUid': return item.TestRun.TestRunUid;
            default: return item[property];
          }
        };
      }
      dataSource.sort = this.sort;
      // set a new filterPredicate function to be able to filter nested objects
      dataSource.filterPredicate = (data, filter: string) => {
        const accumulator = (currentTerm, key) => {
          return this.nestedFilterCheck(currentTerm, data, key);
        };
        const dataStr = Object.keys(data).reduce(accumulator, '').toLowerCase();
        const transformedFilter = filter.trim().toLowerCase();
        return dataStr.indexOf(transformedFilter) !== -1;
      };
      this.dataSource = dataSource;
    }
    this.getTestRunByDateRunning = false;
  }

  nestedFilterCheck(search, data, key) {
    if (typeof data[key] === 'object') {
      for (const k in data[key]) {
        if (data[key][k] !== null) {
          search = this.nestedFilterCheck(search, data[key], k);
        }
      }
    } else {
      search += data[key];
    }
    return search;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  navigateTo(row: any) {

    this.router.navigate(['/testresult/testrun/' + row.TestRun.TestRunUid]);

  }
}
