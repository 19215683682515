import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { Classification, ClassificationReason } from 'src/enums/classification';
import { TestRunStatus } from 'src/enums/testRunStatus';
import { environment } from 'src/environments/environment';
import { TargetEnvironment } from 'src/environments/environment.interfaces';
import { OverviewItem } from 'src/models/overview';
import { FPC } from 'src/models/sops';
import { TestResult } from 'src/models/testResult';
import { TroubleReport } from 'src/models/troubleReport';
import { GetTestRunAction } from 'src/services/Actions/TestRunAction';
import { FileService } from 'src/services/file.service';
import { TestRunBaseService } from 'src/services/test-run.base.service';
import { TmtLoggerService } from 'tmt-logger';
import { TestRunStatistics, TestRunViewModel } from '../../../../models/testRun';
import { TestRunService } from '../../../../services/test-run.service';
import { DialogComponent } from '../dialog/dialog.component';
import { HistoryComponent } from '../history/history.component';

export interface NotExecutedType {
  name: string;
  value: string;
}
@Component({
  selector: 'app-test-run',
  templateUrl: './test-run.component.html',
  styleUrls: ['./test-run.component.css'],
  providers: [ { provide: FileService } ]
})
export class TestRunComponent implements OnInit, OnDestroy {

  view: any[] = [650, 350];
  single: any[];
  colorScheme = {
    domain: ['#438151', '#D6001C', '#16417F', '#FA6E23', '#CEB888', '#82848A', '#A6D1AD']
  };

  public xcomFiles$ =  this.fileService.xcomFiles$
  public atsFiles$ = this.fileService.atsFiles$
  public e2Files$ = this.fileService.e2Files$
  public offboardFiles$ = this.fileService.offboardFiles$

  constructor(
    private testRunBaseService: TestRunBaseService,
    private testRunService: TestRunService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private router: Router,
    private testRunActionService: GetTestRunAction,
    private loggerService: TmtLoggerService,
    private fileService: FileService
  ) {
    if (this.environment !== TargetEnvironment.Hero)
      this.displayedColumns = ['TestCaseName', 'Result', 'TroubleReports', 'ResultComment', 'FailedReasonText', 'FailedBy', 'FailedReasonComment', 'TestCaseAlternativeFailedReasons'];
    else
      this.displayedColumns = ['TestCaseName', 'Result', 'TroubleReports', 'ResultComment', 'FailedReasonText', 'FailedBy', 'FailedReasonComment'];
  }


  testRun: TestRunViewModel;
  displayedColumns: string[] = []
  // TODO show Classification and ClassificationComment when we have data
  //  displayedColumns: string[] = ['TestCaseName', 'Result', 'TroubleReports','ResultComment', 'Classification', 'ClassificationComment',
  //  'FailedReasonText', 'FailedBy', 'FailedReasonComment'];
  dataSource;
  maxall = 10;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // TODO Classification is not yet saved on test result or in history table
  parentTypeID = 92; // TestResult
  fieldName = 'Classification';
  private subscription: Subscription;
  includesNotExecuted: boolean;
  countPassed: number = 0;
  countFailed: number = 0;
  countNotExecutedBySystem: number = 0;
  countNotExecutedByUser: number = 0;
  countOther: number = 0;
  private statisticsSubscription: Subscription;
  testRunStatistics: TestRunStatistics;
  notExecutedTypes: NotExecutedType[] = [
    { name: 'FailedBySystem', value: 'N/A by system' },
    { name: 'FailedByUser', value: 'N/A / N/P by user.' }
  ];
  testResultStatistics: OverviewItem[] = [{ name: '', value: 0 }];
  troubleReportsInStatistics: TroubleReport[] = [{ Name: '', Uri: '', TestResultUid: '', ValidFrom: new Date, ValidTo: new Date }];
  uniqueTroubleReportNamesInStatistics: string[];
  failedResults: TestResult[] = [<TestResult>{}];
  failedResultsWithoutTroubleReport: TestResult[] = [<TestResult>{}];
  originEcuReadout: string;
  fileTimeEcuReadout: Date;
  totalEcuReadouts: number;
  totalEcuParams: number;
  totalRunProperties: number;
  totalSOPS: number;
  totalOffboardComponents: number;
  originSOPS: string;
  fileTimeSOPS: Date;
  versionSOPS: number;
  FPCsSOPS: FPC[];
  classificationDescription: string;
  classificationReasonDescription: string;
  testRunStatusDescription: string;
  reportsurl = environment.reportsurl;
  gettingTestRuns: boolean;
  resultsExist: boolean = true;
  tcAlternativeFailedReasonsExist: boolean = true;
  environment = environment.environment
  TargetEnvironment = TargetEnvironment

  ngOnInit() {
    this.route.params.subscribe(parmas => this.testRunActionService.getTestRun(parmas.uid));
    this.getTestRun();
  }

  getTestRun() {
    this.resultsExist = true;
    this.gettingTestRuns = true;
    this.testRunActionService.testrunData.subscribe(testrundata => {
      if (testrundata.TestRun.TestResults.length === 0) {
        this.resultsExist = false;
      }
      else {
        this.fileService.initFilesInformationForTestRun(testrundata.TestRun.TestRunUid);
        this.testRun = testrundata;
        this.classificationDescription = Classification[testrundata.TestRun.Classification];
        this.classificationReasonDescription = ClassificationReason[testrundata.TestRun.ClassificationReason];
        this.testRunStatusDescription = TestRunStatus[testrundata.TestRun.Status];
        this.includesNotExecuted = testrundata.TestRun.TestResults.some(x => x.FailedReason !== 0);
        this.tcAlternativeFailedReasonsExist = testrundata.TestRun.TestResults.some(x => x.testCaseAlternativeFailedReasons.length > 0);
        this.dataSource = new MatTableDataSource<TestResult>(testrundata.TestRun.TestResults);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'TestCaseName': return item.TestCaseName;
            case 'Result': return item.Result;
            case 'TroubleReports': return item.troubleReports.length;
            case 'ResultComment': return item.ResultComment;
            case 'FailedReasonText': return item.FailedReasonText;
            case 'FailedBy': return item.FailedBy;
            case 'FailedReasonComment': return item.FailedReasonComment;
            default: return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.testRunService.selectedRunUid.next(testrundata.TestRun.TestRunUid);
        this.distributeResultsToStatistics(this.testRun);
        this.getFailedWithoutTroubleReports();
        this.getTestRunStatistics();
      }
      this.gettingTestRuns = false;
    },
      error => {
        this.loggerService.logError(error);
        this.gettingTestRuns = false;
      }
    );
  }

  onChangeNotExecutedType(name: string, isChecked: boolean) {
    if (isChecked) {
      this.testRun.TestRun.TestResults.forEach(result => {
        if (name === 'FailedBySystem' && result.FailedReason !== 0 && result.FailedBy === 'System') // System
        { result.hide = false; }
        else if (name === 'FailedByUser' && result.FailedReason !== 0 && result.FailedBy !== 'System') // User
        { result.hide = false; }
      });
    }
    else {
      this.testRun.TestRun.TestResults.forEach(result => {
        if (name === 'FailedBySystem' && result.FailedReason !== 0 && result.FailedBy === 'System') // System
        { result.hide = true; }
        else if (name === 'FailedByUser' && result.FailedReason !== 0 && result.FailedBy !== 'System') // User
        { result.hide = true; }
      });
    }
  }

  openHistoryDialog(parentUidIn): void {
    const dialogRef = this.dialog.open(HistoryComponent, {
      width: '800px',
      data: { parentUid: parentUidIn, parentTypeID: this.parentTypeID, fieldName: this.fieldName }
    });
  }

  openDialog(header, text): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '800px',
      data: { header: header, text: text }
    });
  }

  getPageSizeOptions(): number[] {
    if (this.dataSource && this.dataSource.paginator && this.dataSource.paginator.length > this.maxall) {
      return [10, 20, this.dataSource.paginator.length];
    } else {
      return [10, 20];
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  navigateTo(row: any) {
    this.router.navigate(['/testresult/' + row.TestResultUid]);
  }

  distributeResultsToStatistics(run: TestRunViewModel) {
    this.troubleReportsInStatistics.length = 0;
    this.failedResults.length = 0;
    this.failedResultsWithoutTroubleReport.length = 0;
    this.countPassed = 0;
    this.countFailed = 0;
    this.countNotExecutedBySystem = 0;
    this.countNotExecutedByUser = 0;
    this.countOther = 0;

    run.TestRun.TestResults.forEach(res => {
      if (res.Result === 'passed') {
        this.countPassed += 1;
      }
      else if (res.Result === 'failed') {
        this.countFailed += 1;
        this.failedResults.push(res);
      }
      else if (res.FailedReason === 1 && res.FailedBy === 'System') {
        this.countNotExecutedBySystem += 1;
      }
      else if (res.FailedBy === 'User') {
        this.countNotExecutedByUser += 1;
      }
      else {
        this.countOther += 1;
      }

      if (res.troubleReports.length > 0) {
        res.troubleReports.forEach(tr => {
          this.troubleReportsInStatistics.push(tr);
        })
      }
    }
    );

    this.uniqueTroubleReportNamesInStatistics = [...new Set(this.troubleReportsInStatistics.map(tr => tr.Name))];

    this.testResultStatistics = [
      { name: 'Passed' + ' (' + this.countPassed + ')', value: this.countPassed },
      { name: 'Failed' + ' (' + this.countFailed + ')', value: this.countFailed },
      { name: 'Not executed by System' + ' (' + this.countNotExecutedBySystem + ')', value: this.countNotExecutedBySystem },
      { name: 'Not executed by User' + ' (' + this.countNotExecutedByUser + ')', value: this.countNotExecutedByUser },
      { name: 'Other' + ' (' + this.countOther + ')', value: this.countOther }
    ];
  }

  getFailedWithoutTroubleReports() {
    this.failedResults.forEach(failedRes => {
      if (failedRes.troubleReports.length === 0) {
        this.failedResultsWithoutTroubleReport.push(failedRes);
      }
    })
  }

  onSelect(data): void {
    // console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    // console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    // console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  getTestRunStatistics() {
    this.statisticsSubscription = this.route.params.pipe(switchMap(params =>
      this.testRunBaseService.getTestRunStatistics(params.uid)
    )).subscribe(teststatisticsdata => {
      this.testRunStatistics = teststatisticsdata;
    },
      error => this.loggerService.logError(error)
    );
  }

  public showEcuReadoutCounts(count: number): void {
    this.totalEcuReadouts = count;
  }
  public showEcuReadoutOrigin(originText: string): void {
    this.originEcuReadout = originText;
  }
  public showEcuReadoutFileTime(fileTime: Date): void {
    this.fileTimeEcuReadout = fileTime;
  }
  public showEcuParamCounts(count: number): void {
    this.totalEcuParams = count;
  }
  public showSOPSCounts(count: number): void {
    this.totalSOPS = count;
  }
  public showSOPSOrigin(originText: string): void {
    this.originSOPS = originText;
  }
  public showSOPSFileTime(fileTime: Date): void {
    this.fileTimeSOPS = fileTime;
  }
  public showSOPSVersion(version: number): void {
    this.versionSOPS = version;
  }
  public showSOPSFPCs(fpcs: FPC[]): void {
    this.FPCsSOPS = fpcs;
  }
  public showRunPropertiesCount(count: number): void {
    this.totalRunProperties = count;
  }
  public showOffboardComponentsCount(count: number): void {
    this.totalOffboardComponents = count;
  }


  ngOnDestroy(): void {
    if (this.statisticsSubscription) {
      this.statisticsSubscription.unsubscribe();
    }
  }
}






