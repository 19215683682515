import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-results-page',
  templateUrl: './test-results-page.component.html',
  styleUrls: ['./test-results-page.component.css']
})
export class TestResultsPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
