import { Component, OnInit, OnChanges, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { TroubleReport } from 'src/models/troubleReport';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TmtLoggerService } from 'tmt-logger';
import { TroubleReportBaseService } from 'src/services/trouble-report.base.service';
@Component({
  selector: 'app-trouble-reports',
  templateUrl: './trouble-reports.component.html',
  styleUrls: ['./trouble-reports.component.css']
})
export class TroubleReportsComponent implements OnChanges {

  constructor(private troubleReportBaseService: TroubleReportBaseService, private loggerService: TmtLoggerService) { }

  troubleReports: Array<TroubleReport>;

  displayedColumns = ['Jira Issue', 'ValidFrom', 'ValidTo'];
  dataSource: MatTableDataSource<TroubleReport>;
  @ViewChild(MatSort) sort: MatSort;

  @Input() resultUid: string;
  @Output() countTroubleReports: EventEmitter<any> = new EventEmitter();

  ngOnChanges() {
    this.getTroubleReports(this.resultUid);
  }

  getTroubleReports(resultUid: string) {
    this.troubleReportBaseService.getTroubleReports(resultUid)
      .subscribe(data => {
        // if (data && data.length > 0) {  //TODO why is data undefined?
        this.troubleReports = data;
        this.countTroubleReports.emit(this.troubleReports.length);
        this.dataSource = new MatTableDataSource<TroubleReport>(this.troubleReports);
        this.dataSource.sort = this.sort;
        // }
      },
        error => this.loggerService.logError(error)
      );
  }
}
