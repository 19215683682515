import { Component, ElementRef, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { RequirementWithResults, TestedInTestRuns, TestedSoftwaresForEcu } from 'src/models/requirement';
import { SpecificationService } from 'src/services/specification.service';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DatePipe } from '@angular/common';
import { OverviewItem } from 'src/models/overview';
import { TestRun, TestRunAndProperties } from 'src/models/testRun';
import { Identifier } from 'src/models/identifier';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { TestAnalysisVersionResult, TestAnalysisWithResults } from 'src/models/testAnalysis';
import { TestCaseWithResults } from 'src/models/testCase';
import { MultiSelect } from 'primeng/multiselect';
import { TmtLoggerService } from 'tmt-logger';

interface EcuTreeNode {
  name: string;
  children?: EcuTreeNode[];
  identifiers: Identifier[];
  testedInTestRuns: TestedInTestRuns[];
}

interface TreeNode {
  name: string;
  children?: TreeNode[];
  className: string;
  url?: string;
  extraInfo?: string;
  version?: number;
  type: string;
}

/** Flat node with expandable and level information */

interface ExampleEcuFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  identifiers: Identifier[]
  testRuns: TestRun[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  className: string;
  url: string;
  extraInfo: string;
  version: number;
}

interface RequirementFilter {
  requirements: RequirementWithResults[];
  text: string;
}

interface Filter {
  propertyFilterOnTestRuns: TestRunAndProperties[];
  softwareFilterOnTestRuns: TestedInTestRuns[];
  requirementFilter: RequirementFilter;
};

//We need an interface for the property values since the PrimeNg MultiSelect can't filter on a string array
class PropertyFilterValue {
  filterValue: string;
}

class PropertyFilter {
  name: string;
  filterValues: PropertyFilterValue[];
}

@Component({
  selector: 'app-requirement-fulfillment',
  templateUrl: './requirement-fulfillment.component.html',
  styleUrls: ['./requirement-fulfillment.component.css'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None //To override the styling for p-multiSelect
})



export class RequirementFulfillmentComponent implements OnInit {
  @ViewChildren("softwarecheckboxes") softwarecheckboxes: QueryList<ElementRef>;
  @ViewChildren('propertyDropdowns') components: QueryList<MultiSelect>;
  view: any[] = [750, 300];
  viewAsil: any[] = [650, 200];
  colorScheme = {
    domain: ['#438151', '#D6001C', '#16417F', '#FA6E23', '#CEB888', '#82848A', '#A6D1AD']
  };
  colorSchemeAsilDiagrams = {
    domain: ['#438151', '#D6001C']
  };
  colorSchemeAsilDiagramsWithNotPerformed = {
    domain: ['#438151', '#D6001C', '#FA6E23']
  };
  pieChartDataTestDesign: OverviewItem[] = [{ name: '', value: 0 }];
  pieChartDataAsilQm: OverviewItem[] = [{ name: '', value: 0 }];
  pieChartDataAsilA: OverviewItem[] = [{ name: '', value: 0 }];
  pieChartDataAsilB: OverviewItem[] = [{ name: '', value: 0 }];
  pieChartDataAsilC: OverviewItem[] = [{ name: '', value: 0 }];
  pieChartDataAsilD: OverviewItem[] = [{ name: '', value: 0 }];

  piechartSliceRequirementWithTestResults: string = "Requirements with Test Results";
  piechartSliceRequirementWithNoTestResults: string = "Requirements with missing Test Results";
  specificationId: string;
  irmaVersion: number;
  isLoading: boolean;
  allRequirementsInSpecification: RequirementWithResults[];
  allTestedSoftwareForEcuInSpecification: TestedSoftwaresForEcu[];
  requirementsWithTestResult: RequirementWithResults[] = []
  requirementsWithNoTestResult: RequirementWithResults[] = []
  filter: Filter = { propertyFilterOnTestRuns: null, softwareFilterOnTestRuns: null, requirementFilter: null };
  doShowResultsForAllVersions: boolean = false;



  private _transformerEcu = (node: EcuTreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      testRuns: node.testedInTestRuns,
      identifiers: node.identifiers
    };
  };

  private _transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      className: node.className,
      url: node.url,
      extraInfo: node.extraInfo,
      version: node.version,
      type: node.type
    };
  };

  ecuTreeControl = new FlatTreeControl<ExampleEcuFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  requirementTreeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  ecuTreeFlattener = new MatTreeFlattener(
    this._transformerEcu,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  allPropertyValuesForAllTestRuns: PropertyFilter[] = [];
  selectedPropertyValues: PropertyFilter[] = [];
  allTestRunsAndProperties: TestRunAndProperties[] = [];
  ecuTreeDataSource = new MatTreeFlatDataSource(this.ecuTreeControl, this.ecuTreeFlattener);
  requirementTreeDataSource = new MatTreeFlatDataSource(this.requirementTreeControl, this.treeFlattener);
  readonly inconclusiveIcon: string = "glyphicon glyphicon-question-sign text-warning"
  readonly successIcon: string = "glyphicon glyphicon-ok-sign text-success";
  readonly failureIcon: string = "glyphicon glyphicon-remove-sign text-danger";
  readonly missingIcon: string = "glyphicon glyphicon-minus";
  showPropertyFilter: boolean;
  showSoftwareFilter: boolean;
  showStatistics: boolean;

  constructor(
    private requirementFulfillmentService: SpecificationService,
    public datepipe: DatePipe,
    private messageService: MessageService,
    private primengConfig: PrimeNGConfig,
    private loggerService: TmtLoggerService) { }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngOnInit() {
    this.isLoading = false;
    this.primengConfig.ripple = true;
  }

  buildEcuAndPropertyTreesForFilters() {
    var treeNodesWithSoftware: EcuTreeNode[] = [];
    this.allTestedSoftwareForEcuInSpecification.forEach(ecu => {
      var softwaresTreeNode: EcuTreeNode[] = [];
      ecu.TestedSoftwares.forEach(software => {
        var softwareTreeNode: EcuTreeNode = { name: "", testedInTestRuns: software.TestedInTestRuns, identifiers: software.Identifiers };
        softwaresTreeNode.push(softwareTreeNode);
      });
      var ecuTreeNode: EcuTreeNode = { name: ecu.EcuName, testedInTestRuns: [], identifiers: [], children: softwaresTreeNode };
      treeNodesWithSoftware.push(ecuTreeNode);
    });
    this.ecuTreeDataSource.data = treeNodesWithSoftware
  }

  drawTotalTestCoveragePieChart(requirements: RequirementWithResults[]) {
    this.requirementsWithTestResult = []
    requirements.forEach((requirement) => {
      requirement.TestAnalyses.forEach(ta => {
        //if(ta.TestAnalysisCondition != null){        
        ta.TestCases.forEach(tc => {
          tc.Results.forEach(tr => {
            if (!this.requirementsWithTestResult.find(r => r.RequirementID === requirement.RequirementID)) {
              this.requirementsWithTestResult.push(requirement)
            }
          })
        })
        //}
      })
    });
    this.requirementsWithNoTestResult = [];
    this.allRequirementsInSpecification.forEach(req => {
      if (!this.requirementsWithTestResult.find(reqWithResult => reqWithResult.RequirementID === req.RequirementID)) {
        this.requirementsWithNoTestResult.push(req);
      }
    });

    this.pieChartDataTestDesign = [
      { name: `${this.piechartSliceRequirementWithTestResults}(${this.requirementsWithTestResult.length})`, value: this.requirementsWithTestResult.length },
      { name: `${this.piechartSliceRequirementWithNoTestResults}(${this.requirementsWithNoTestResult.length})`, value: this.requirementsWithNoTestResult.length },
    ];
    // #region Hard coded pie charts
    this.pieChartDataAsilQm = [
      { name: `"Required based test" Passed (36)`, value: 36 },
      { name: `"Required based test" Failed (11)`, value: 11 }
    ];

    this.pieChartDataAsilA = [
      { name: `"Requirement based test" Passed (4)`, value: 4 },
      { name: `"Requirement based test" Failed (2)`, value: 2 },
      { name: `"Fault injection test" Passed (7)`, value: 7 },
      { name: `"Fault injection test" Failed (1)`, value: 1 },
      { name: `"Performance test" Passed (4)`, value: 4 },
      { name: `"Performance test" Failed (5)`, value: 5 },
      { name: `"Error guessing test" Passed (8)`, value: 8 },
      { name: `"Error guessing test" Failed (1)`, value: 1 },
      { name: `"Test of external interfaces" Passed (4)`, value: 4 },
      { name: `"Test of external interfaces" Failed (4)`, value: 4 },
      { name: `"Test of external interfaces" Passed (1)`, value: 1 },
      { name: `"Test of external interfaces" Failed (5)`, value: 5 },
      { name: `"Interface consistency check" Passed (2)`, value: 2 },
      { name: `"Interface consistency check" Failed (4)`, value: 4 },
      { name: `"Test of interaction/communication" Passed (1)`, value: 1 },
      { name: `"Test of interaction/communication" Failed (2)`, value: 2 },
      { name: `"Resource usage test" Passed (5)`, value: 5 },
      { name: `"Resource usage test" Failed (4)`, value: 4 },
      { name: `"Stress test" Passed (3)`, value: 3 },
      { name: `"Stress test" Failed (2)`, value: 2 },
    ];

    this.pieChartDataAsilB = [
      { name: `"Requirement based test" Passed (4)`, value: 4 },
      { name: `"Requirement based test" Failed (2)`, value: 2 },
      { name: `"Fault injection test" Passed (7)`, value: 7 },
      { name: `"Fault injection test" Failed (1)`, value: 1 },
      { name: `"Performance test" Passed (4)`, value: 4 },
      { name: `"Performance test" Failed (5)`, value: 5 },
      { name: `"Error guessing test" Passed (8)`, value: 8 },
      { name: `"Error guessing test" Failed (1)`, value: 1 },
      { name: `"Test of external interfaces" Passed (4)`, value: 4 },
      { name: `"Test of external interfaces" Failed (4)`, value: 4 },
      { name: `"Test of external interfaces" Passed (1)`, value: 1 },
      { name: `"Test of external interfaces" Failed (5)`, value: 5 },
      { name: `"Interface consistency check" Passed (2)`, value: 2 },
      { name: `"Interface consistency check" Failed (4)`, value: 4 },
      { name: `"Test of interaction/communication" Passed (1)`, value: 1 },
      { name: `"Test of interaction/communication" Failed (2)`, value: 2 },
      { name: `"Resource usage test" Passed (5)`, value: 5 },
      { name: `"Resource usage test" Failed (4)`, value: 4 },
      { name: `"Stress test" Passed (3)`, value: 3 },
      { name: `"Stress test" Failed (2)`, value: 2 },
    ];

    this.pieChartDataAsilC = [
      { name: `"Requirement based test" Passed (4)`, value: 4 },
      { name: `"Requirement based test" Failed (2)`, value: 2 },
      { name: `"Fault injection test" Passed (7)`, value: 7 },
      { name: `"Fault injection test" Failed (1)`, value: 1 },
      { name: `"Performance test" Passed (4)`, value: 4 },
      { name: `"Performance test" Failed (5)`, value: 5 },
      { name: `"Error guessing test" Passed (8)`, value: 8 },
      { name: `"Error guessing test" Failed (1)`, value: 1 },
      { name: `"Test of external interfaces" Passed (4)`, value: 4 },
      { name: `"Test of external interfaces" Failed (4)`, value: 4 },
      { name: `"Test of external interfaces" Passed (1)`, value: 1 },
      { name: `"Test of external interfaces" Failed (5)`, value: 5 },
      { name: `"Interface consistency check" Passed (2)`, value: 2 },
      { name: `"Interface consistency check" Failed (4)`, value: 4 },
      { name: `"Test of interaction/communication" Passed (1)`, value: 1 },
      { name: `"Test of interaction/communication" Failed (2)`, value: 2 },
      { name: `"Resource usage test" Passed (5)`, value: 5 },
      { name: `"Resource usage test" Failed (4)`, value: 4 },
      { name: `"Stress test" Passed (3)`, value: 3 },
      { name: `"Stress test" Failed (2)`, value: 2 },
    ];

    this.pieChartDataAsilD = [
      { name: `"Requirement based test" Passed (4)`, value: 4 },
      { name: `"Requirement based test" Failed (2)`, value: 2 },
      { name: `"Requirement based test" Not Performed (2)`, value: 2 },
      { name: `"Fault injection test" Passed (7)`, value: 7 },
      { name: `"Fault injection test" Failed (1)`, value: 1 },
      { name: `"Fault injection test" Not Performed (2)`, value: 2 },
      { name: `"Performance test" Passed (4)`, value: 4 },
      { name: `"Performance test" Failed (5)`, value: 5 },
      { name: `"Performance test" Not Performed (3)`, value: 3 },
      { name: `"Error guessing test" Passed (8)`, value: 8 },
      { name: `"Error guessing test" Failed (1)`, value: 1 },
      { name: `"Error guessing test" Not Perf (3)`, value: 3 },
      { name: `"Test of external interfaces" Passed (4)`, value: 4 },
      { name: `"Test of external interfaces" Failed (4)`, value: 4 },
      { name: `"Test of external interfaces" Not Performed (5)`, value: 5 },
      { name: `"Test of internal interfaces" Passed (1)`, value: 1 },
      { name: `"Test of internal interfaces" Failed (5)`, value: 5 },
      { name: `"Test of inxternal interfaces" Not Performed (0)`, value: 0 },
      { name: `"Interface consistency check" Passed (2)`, value: 2 },
      { name: `"Interface consistency check" Failed (4)`, value: 4 },
      { name: `"Interface consistency check" Not Performed (1)`, value: 1 },
      { name: `"Test of interaction/communication" Passed (1)`, value: 1 },
      { name: `"Test of interaction/communication" Failed (2)`, value: 2 },
      { name: `"Test of interaction/communication" Not Performed (5)`, value: 5 },
      { name: `"Resource usage test" Passed (5)`, value: 5 },
      { name: `"Resource usage test" Failed (4)`, value: 4 },
      { name: `"Resource usage test" Not Performed (2)`, value: 2 },
      { name: `"Stress test" Passed (3)`, value: 3 },
      { name: `"Stress test" Failed (2)`, value: 2 },
      { name: `"Stress test" Not Performed (3)`, value: 3 }
    ];
    // #endregion    
  }


  buildRequirementsTree(requirements: RequirementWithResults[]) {
    var requirementsTreeNodes: TreeNode[] = [];
    var resultsForRequirementExists: boolean;
    requirements.forEach((requirement) => {
      resultsForRequirementExists = false;
      var iconsForRequirement: string = "";
      var testAnalysisTreeNodes: TreeNode[] = []
      var resultsForRequirement: string[] = [];
      if (requirement.TestAnalyses.length > 0) {
        iconsForRequirement += "TA ";
        requirement.TestAnalyses.forEach(ta => {

          //if (ta.TestAnalysisCondition != null) {
          var testCaseTreeNodes: TreeNode[] = []
          testCaseTreeNodes = [];
          var noResultsText = `There are no results for this test case or you are not authorized to view them`;
          var resultsForTestAlternative: string[] = [];

          if (ta.TestCases.length > 0) {
            if (!iconsForRequirement.includes("TC")) {
              iconsForRequirement += "TC ";
            }
            ta.TestCases.forEach((testCase) => {
              var testResultTreeNodes: TreeNode[] = []
              var resultsForTestCase: string[] = [];

              if (testCase.Results != null && testCase.Results.length > 0) {
                if (!iconsForRequirement.includes("TR ")) {
                  iconsForRequirement += "TR ";
                }
                testCase.Results.forEach((result) => {
                  if (this.doShowResultsForAllVersions || result.IsHighestTestCaseVersion) {
                    resultsForRequirementExists = true;
                    var resultForTestCase: string[] = [result.Result]
                    var testResultTreeNode: TreeNode = {
                      name: `Result (${this.datepipe.transform(result.ExecutionTimeTestRun, 'yyyy-MM-dd HH:mm:ss')}): ${result.Result}`,
                      className: this.getIconBasedOnResults(resultForTestCase),
                      url: `/#/testresult/${result.TestResultUid}`,
                      type: "TestResult",
                      extraInfo: `Test Analysis version ${result.TestAnalysisVersion}, Test Case version ${result.TestCaseVersion}`
                    }
                    testResultTreeNodes.push(testResultTreeNode);
                    resultsForTestCase.push(result.Result);
                    resultsForTestAlternative.push(result.Result);
                    resultsForRequirement.push(result.Result);
                  };
                });
              }
              else {
                //only show empty nodes if we have not filtered on test runs
                var testResultTreeNode: TreeNode = { name: noResultsText, className: "", type: "TestResult" }
                testResultTreeNodes.push(testResultTreeNode);
                resultsForTestCase.push("none");
                resultsForTestAlternative.push("none");
                resultsForRequirement.push("none");
              }
              var taAndTcVersionIfNoResult = testCase.Results.length == 0 ? `Test Analysis version ${ta.HighestVersion.toString()}, Test Case version ${testCase.HighestVersion.toString()}` : "";
              var testCaseTreeNode: TreeNode = {
                name: `Test Case: ${testCase.TestCaseName}`,
                children: testResultTreeNodes,
                className: this.getIconBasedOnResults(resultsForTestCase),
                url: `/#/testcasedetails?uid=${testCase.TestCaseUid}`,
                type: "TestCase",
                extraInfo: taAndTcVersionIfNoResult
              }
              testCaseTreeNodes.push(testCaseTreeNode);
            });
          }
          else {
            var testCaseTreeNode: TreeNode = { name: `Test Cases are missing`, className: "", type: "TestCase" }
            testCaseTreeNodes.push(testCaseTreeNode);
          }
          var taVersionIfNoTc = ta.TestCases.length == 0 ? `Test Analysis version ${ta.HighestVersion.toString()} ` : "";
          var testAnalysisTreeNode: TreeNode = {
            name: `Test Analysis: ${ta.Name} - ${ta.TestAnalysisConditionName}`,
            className: this.getIconBasedOnResults(resultsForTestAlternative),
            type: "TA",
            children: testCaseTreeNodes,
            extraInfo: taVersionIfNoTc
          }
          testAnalysisTreeNodes.push(testAnalysisTreeNode);
        });

      }
      else {
        var testAnalysisTreeNode: TreeNode = { name: `Test Analysis is missing`, className: "", type: "TaAlternative" };
        testAnalysisTreeNodes.push(testAnalysisTreeNode);
      }

      var requirementTreeNode: TreeNode = {
        name: requirement.Name,
        children: testAnalysisTreeNodes,
        className: this.getIconBasedOnResults(resultsForRequirement),
        extraInfo: iconsForRequirement,
        version: requirement.IrmaVersion,
        type: "Requirement"
      };
      requirementsTreeNodes.push(requirementTreeNode);
    });
    this.requirementTreeDataSource.data = requirementsTreeNodes

  }

  //#region Create objects
  createRequirement(requirement: RequirementWithResults): RequirementWithResults {
    let newRequirement: RequirementWithResults = {
      RequirementID: requirement.RequirementID,
      Name: requirement.Name,
      Version: requirement.Version,
      ASiL: requirement.ASiL,
      TestAnalyses: [],
      IrmaVersion: requirement.IrmaVersion
    };
    return newRequirement;
  }

  createTestCase(tc: TestCaseWithResults): TestCaseWithResults {
    let newTestCase: TestCaseWithResults = {
      Results: [],
      TestCaseUid: tc.TestCaseUid,
      TestCaseName: tc.TestCaseName,
      HighestVersion: tc.HighestVersion
    };
    return newTestCase
  }

  createTestAnalysisVersionResult(tr: TestAnalysisVersionResult): TestAnalysisVersionResult {
    let newTestResult: TestAnalysisVersionResult = {
      TestResultUid: tr.TestResultUid,

      ExecutionTimeTestRun: tr.ExecutionTimeTestRun,
      ExecutionTimeTestResult: tr.ExecutionTimeTestResult,
      Result: tr.Result,

      TestRunUid: tr.TestRunUid,
      TestCaseUid: tr.TestCaseUid,
      TestCaseName: tr.TestCaseName,
      TestCaseVersion: tr.TestCaseVersion,
      TestAnalysisVersion: tr.TestAnalysisVersion,
      IsHighestTestCaseVersion: tr.IsHighestTestCaseVersion
    }
    return newTestResult;
  }

  createTestAnalysisWithResults(testAnalysis: TestAnalysisWithResults): TestAnalysisWithResults {
    let newTestAnalysis: TestAnalysisWithResults = {
      UID: testAnalysis.UID,
      Name: testAnalysis.Name,
      TestCases: [],
      TestAnalysisConditionName: testAnalysis.TestAnalysisConditionName,
      TestAnalysisConditionVersion: testAnalysis.TestAnalysisConditionVersion,
      HighestVersion: testAnalysis.HighestVersion
    }
    return newTestAnalysis;
  }


  updateTestRunFilterAndRenderResult = () => {
    let filteredRequirements: RequirementWithResults[] = [];
    if (this.filter.propertyFilterOnTestRuns === null && this.filter.softwareFilterOnTestRuns === null) {
      filteredRequirements = this.allRequirementsInSpecification;
    }
    else {
      this.allRequirementsInSpecification.forEach(requirement => {
        const newRequirement = this.createRequirement(requirement);
        requirement.TestAnalyses.forEach(ta => {
          const newTestAnalysis = this.createTestAnalysisWithResults(ta);
          ta.TestCases.forEach(tc => {
            let newTestCase = this.createTestCase(tc);
            if (tc.Results !== null) {
              tc.Results.forEach(tr => {
                let newTestResult = this.createTestAnalysisVersionResult(tr);
                if ((this.filter.propertyFilterOnTestRuns === null || this.filter.propertyFilterOnTestRuns.some(s => s.TestRunUid === tr.TestRunUid))
                  && (this.filter.softwareFilterOnTestRuns === null || this.filter.softwareFilterOnTestRuns.some(s => s.TestRunUid === tr.TestRunUid))) {
                  newTestCase.Results.push(newTestResult);
                }
              })
            }
            if (newTestCase.Results.length > 0) {
              newTestAnalysis.TestCases.push(newTestCase);
            }
          })
          if (newTestAnalysis.TestCases.length > 0) {
            newRequirement.TestAnalyses.push(newTestAnalysis);
          }
        })
        if (newRequirement.TestAnalyses.length > 0) {
          filteredRequirements.push(newRequirement);
        }
      });
    }
    this.buildRequirementsTree(filteredRequirements);
    this.showFilterAppliedToast();
  }

  updateRequirementFilterAndRenderResult = () => {
    this.buildRequirementsTree(this.filter.requirementFilter.requirements);
    this.showFilterAppliedToast();
  }



  onPropertyValueChange(event, dropDownPropertyName) {
    const dropDownPropertyValues = event.value;
    let propertyValues: PropertyFilterValue[] = [];
    dropDownPropertyValues.forEach(v => {
      propertyValues.push(v);
    });
    const existingProperty = this.selectedPropertyValues.find(p => p.name === dropDownPropertyName);
    if (existingProperty === undefined) {
      const newProperty: PropertyFilter = { name: dropDownPropertyName, filterValues: propertyValues };
      this.selectedPropertyValues.push(newProperty);
    } else {
      existingProperty.filterValues = propertyValues;
    }
    this.onPropertyFilter();
  }

  onPropertyFilter() {
    this.clearPieChartFilter(); //Reset pie chart filter so we can filter only on test runs
    this.doShowResultsForAllVersions = true;
    this.filter.propertyFilterOnTestRuns = null;
    this.allPropertyValuesForAllTestRuns.forEach(propertyValue => {
      const filterOnPropertyValues = this.selectedPropertyValues.find(p => p.name === propertyValue.name)
      if (filterOnPropertyValues !== undefined) {
        if (this.filter.propertyFilterOnTestRuns === null) {
          this.filter.propertyFilterOnTestRuns = [];
        }
        this.allTestRunsAndProperties.forEach(testrun => {
          testrun.Properties.forEach(testRunProperty => {
            if (filterOnPropertyValues.name === testRunProperty.Name) {
              if (filterOnPropertyValues.filterValues.find(v => v.filterValue === testRunProperty.Value)) {
                if (!this.filter.propertyFilterOnTestRuns.some(s => s.TestRunUid === testrun.TestRunUid)) {
                  this.filter.propertyFilterOnTestRuns.push(testrun);
                }
              }
            }
          })
        })
      }
    });
    this.updateTestRunFilterAndRenderResult()
  }

  onSoftwareChange() {
    this.clearPieChartFilter(); //Reset pie chart filter so we can filter only on test runs
    this.doShowResultsForAllVersions = true;
    this.filter.softwareFilterOnTestRuns = null;
    this.softwarecheckboxes.forEach(checkboxElement => {
      if (checkboxElement.nativeElement.checked) {
        var testRunUids = checkboxElement.nativeElement.value.split('#');
        testRunUids.forEach(uid => {
          if (this.filter.softwareFilterOnTestRuns === null) {
            this.filter.softwareFilterOnTestRuns = [];
          }
          if (!this.filter.softwareFilterOnTestRuns.some(s => s.TestRunUid === uid)) {
            const testRun = this.getSoftwareTestRun(uid);
            this.filter.softwareFilterOnTestRuns.push(testRun);
          }
        });
      }
    });
    this.updateTestRunFilterAndRenderResult();
  }

  getSoftwareTestRun(uid: any): TestedInTestRuns {
    let returnValue = null
    this.allTestedSoftwareForEcuInSpecification.forEach(testedEcu => {
      testedEcu.TestedSoftwares.forEach(sw => {
        const testRun = sw.TestedInTestRuns.find(tr => tr.TestRunUid === uid);
        if (testRun !== undefined) {
          returnValue = testRun;
        }
      });
    });
    return returnValue;
  }


  onPieChartSelect(selectedSliceName) {
    this.clearAllFilters();
    if (selectedSliceName.startsWith(this.piechartSliceRequirementWithNoTestResults)) {
      this.filter.requirementFilter = { requirements: this.requirementsWithNoTestResult, text: selectedSliceName };
    }
    else if (selectedSliceName.startsWith(this.piechartSliceRequirementWithTestResults)) {
      this.filter.requirementFilter = { requirements: this.requirementsWithTestResult, text: selectedSliceName };
    }
    this.updateRequirementFilterAndRenderResult();
  }

  clearAllFilters() {
    this.clearPieChartFilter();
    this.clearTestRunFilters();
  }

  onClearFilterClick() {
    this.clearAllFilters();
    this.doShowResultsForAllVersions = false;
    this.buildRequirementsTree(this.allRequirementsInSpecification);
  }

  clearPieChartFilter() {
    this.filter.requirementFilter = null;
  }

  clearTestRunFilters() {
    this.selectedPropertyValues = [];
    this.components['_results'].forEach(ds => {
      ds.value = null;
      ds.updateLabel();
    })
    this.filter.propertyFilterOnTestRuns = null;
    this.filter.softwareFilterOnTestRuns = null;
    this.softwarecheckboxes.forEach(checkboxElement => {
      checkboxElement.nativeElement.checked = false;
    });
    this.showSoftwareFilter = false;
    this.showPropertyFilter = false;
  }

  isPropertyFilterApplied(): boolean {
    return this.filter.propertyFilterOnTestRuns !== null || this.filter.requirementFilter !== null || this.filter.softwareFilterOnTestRuns !== null;
  }

  showFilterAppliedToast() {
    this.messageService.add({ severity: 'success', summary: 'Filter applied', detail: 'The filter has been applied to the list' });
  }

  getIconBasedOnResults(resultsForNode: string[]): string {
    if (resultsForNode.every(r => r == "none")) {
      return this.missingIcon;
    }
    if (resultsForNode.every(r => r == "passed")) {
      return this.successIcon;
    }
    if (resultsForNode.every(r => r == "failed")) {
      return this.failureIcon;
    }
    return this.inconclusiveIcon;
  }

  loadDataAndRenderTreeSync(specificationId: string, irmaVersion?: number) {
    this.allRequirementsInSpecification = [];
    this.allTestedSoftwareForEcuInSpecification = [];
    this.allTestRunsAndProperties = [];
    this.allPropertyValuesForAllTestRuns = [];
    this.isLoading = true;
    this.specificationId = specificationId;
    this.irmaVersion = irmaVersion;
    this.requirementFulfillmentService.getTestSpecification(specificationId, irmaVersion)
      .subscribe(requirements => {
        this.isLoading = false;
        this.allRequirementsInSpecification = requirements.Requirements;
        this.allTestedSoftwareForEcuInSpecification = requirements.Softwares;
        this.allTestRunsAndProperties = requirements.Properties;
        this.allPropertyValuesForAllTestRuns = this.getAllPropertyValuesForAllTestRuns(requirements.Properties);
        this.buildEcuAndPropertyTreesForFilters();
        this.buildRequirementsTree(requirements.Requirements);
        this.drawTotalTestCoveragePieChart(requirements.Requirements);
        this.clearAllFilters(); //remove any previous filter       
      },
        error => {
          this.loggerService.logError(error);
          this.isLoading = false;
        });
  }

  getAllPropertyValuesForAllTestRuns(properties: TestRunAndProperties[]): PropertyFilter[] {
    let allPropertyValues: PropertyFilter[] = [];
    properties.forEach(testrun => {
      testrun.Properties.forEach(testrunProperty => {
        if (testrunProperty.Value !== "") {
          let index = allPropertyValues.findIndex(p => p.name === testrunProperty.Name);
          let newValue: PropertyFilterValue = { filterValue: testrunProperty.Value }
          if (index > -1) {
            if (!allPropertyValues[index].filterValues.find(v => v.filterValue == testrunProperty.Value)) {

              allPropertyValues[index].filterValues.push(newValue);
            }
          }
          else {
            let newProperty: PropertyFilter = { name: testrunProperty.Name, filterValues: [newValue] };
            allPropertyValues.push(newProperty);
          }
        }
      })
    })
    allPropertyValues.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0);
    allPropertyValues.forEach(p => {
      p.filterValues.sort((a, b) => a !== b ? a < b ? -1 : 1 : 0);

    })
    return allPropertyValues;
  }



  getTestRunUids(testRuns: TestRun[]) {
    return testRuns.map(tr => tr.TestRunUid).join('#');
  }

  formatTestRunDate(dateToFormat: Date, isLast: boolean): string {
    var formattedDate: string = this.datepipe.transform(dateToFormat, 'yyyy-MM-dd HH:mm:ss')
    return isLast ? formattedDate : formattedDate + ",  ";
  }

  formatList(value: string, isLast: boolean): string {
    return isLast ? value : value + ",  ";
  }

  truncate(str, n) {
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
  };

  changePropertyFilterVisibility() {
    this.showStatistics = false;
    this.showSoftwareFilter = false;
    this.showPropertyFilter = !this.showPropertyFilter;
  }

  changeSoftwareFilterVisibility() {
    this.showStatistics = false;
    this.showSoftwareFilter = !this.showSoftwareFilter;
    this.showPropertyFilter = false;
  }
  changeStatisticsVisibility() {
    this.showStatistics = !this.showStatistics;
    this.showSoftwareFilter = false;
    this.showPropertyFilter = false;
  }

  clearFiltersAndRenderTree() {
    this.clearAllFilters();
    this.buildRequirementsTree(this.allRequirementsInSpecification);
  }

}


