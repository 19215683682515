import { Component, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SOPS, FPC, XPC, FunctionParameter } from '../../../../models/sops';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatAccordion } from '@angular/material/expansion';
import { TmtLoggerService } from 'tmt-logger';
import { SopsBaseService } from 'src/services/sops.base.service';

@Component({
  selector: 'app-sops',
  templateUrl: './sops.component.html',
  styleUrls: ['./sops.component.css']
})
export class SopsComponent implements OnChanges {

  constructor(private sopsBaseService: SopsBaseService, private loggerService: TmtLoggerService) { }

  sops: SOPS;
  dataSourceFPCs;
  displayedColumnsFPCs: string[] = ['Name', 'Value', 'FamilyDescription', 'VariantDescription'];
  countFPCs: number;
  dataSourceXPCs;
  displayedColumnsXPCs: string[] = ['Name', 'Value'];
  countXPCs: number;
  dataSourceFunctionParameters; //ECUs
  displayedColumnsFunctionParameters: string[] = ['Family', 'ParentName', 'Name', 'Value'];
  countFunctionParameters: number;
  maxall = 10;

  @Input() runUid: string;
  @Output() sopsOrigin: EventEmitter<any> = new EventEmitter();
  @Output() sopsFileTime: EventEmitter<any> = new EventEmitter();
  @Output() sopsVersion: EventEmitter<any> = new EventEmitter();
  @Output() countTotalSOPS: EventEmitter<number> = new EventEmitter();
  @Output() sopsFPCs: EventEmitter<any> = new EventEmitter();
  // Special handling when multiple paginators and sorters on same page
  @ViewChild('paginatorFPCs', { read: MatPaginator }) paginatorFPCs: MatPaginator;
  @ViewChild('matSortFPCs') matSortFPCs: MatSort;
  @ViewChild('paginatorXPCs', { read: MatPaginator }) paginatorXPCs: MatPaginator;
  @ViewChild('matSortXPCs') matSortXPCs: MatSort;
  @ViewChild('paginatorFunctionParameters', { read: MatPaginator }) paginatorFunctionParameters: MatPaginator;
  @ViewChild('matSortFunctionParameters') matSortFunctionParameters: MatSort;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  ngOnChanges() {
    this.getSOPS(this.runUid);
  }

  getSOPS(runUid: string) {
    this.sopsBaseService.getSOPS(runUid)
      .subscribe(data => {
        this.sops = data;
        if (this.sops.OriginTypeUid == 205) {
          this.sopsOrigin.emit("File added from Perforce.");
        }
        else if (this.sops.OriginTypeUid == 206) {
          this.sopsOrigin.emit("File uploaded by user.");
        }
        else {
          this.sopsOrigin.emit("");
        }
        this.sopsFileTime.emit(this.sops.FileTime);
        this.sopsVersion.emit(this.sops.VersionControlVersion);
        this.sopsFPCs.emit(this.sops.FPCs);
        this.dataSourceFPCs = new MatTableDataSource<FPC>(data.FPCs);
        this.dataSourceFPCs.paginator = this.paginatorFPCs;
        this.dataSourceFPCs.sort = this.matSortFPCs;
        this.countFPCs = this.dataSourceFPCs.data.length;
        this.dataSourceXPCs = new MatTableDataSource<XPC>(data.XPCs);
        this.dataSourceXPCs.paginator = this.paginatorXPCs;
        this.dataSourceXPCs.sort = this.matSortXPCs;
        this.countXPCs = this.dataSourceXPCs.data.length;
        this.dataSourceFunctionParameters = new MatTableDataSource<FunctionParameter>(data.FunctionParameters);
        this.dataSourceFunctionParameters.paginator = this.paginatorFunctionParameters;
        this.dataSourceFunctionParameters.sort = this.matSortFunctionParameters;
        this.countFunctionParameters = this.dataSourceFunctionParameters.data.length;
        this.countTotalSOPS.emit(this.countFPCs + this.countXPCs + this.countFunctionParameters);
      },
        error => this.loggerService.logError(error)
      );
  }

  getPageSizeOptionsFPCs(): number[] {
    if (this.dataSourceFPCs && this.dataSourceFPCs.paginator && this.dataSourceFPCs.paginator.length > this.maxall) {
      return [10, 20, this.dataSourceFPCs.paginator.length];
    } else {
      return [10, 20];
    }
  }

  applyFilterFPCs(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceFPCs.filter = filterValue.trim().toLowerCase();
  }

  getPageSizeOptionsXPCs(): number[] {
    if (this.dataSourceXPCs && this.dataSourceXPCs.paginator && this.dataSourceXPCs.paginator.length > this.maxall) {
      return [10, 20, this.dataSourceXPCs.paginator.length];
    } else {
      return [10, 20];
    }
  }

  applyFilterXPCs(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceXPCs.filter = filterValue.trim().toLowerCase();
  }

  getPageSizeOptionsFunctionParameters(): number[] {
    if (this.dataSourceFunctionParameters && this.dataSourceFunctionParameters.paginator && this.dataSourceFunctionParameters.paginator.length > this.maxall) {
      return [10, 20, this.dataSourceFunctionParameters.paginator.length];
    } else {
      return [10, 20];
    }
  }

  applyFilterFunctionParameters(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceFunctionParameters.filter = filterValue.trim().toLowerCase();
  }
}
