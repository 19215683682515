import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  //Not used as for now. Instead user time zone offset is sent in request and calculated in API response.
  convertUTCDateToLocalDate(date: Date) {

    let newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();
    // TODO find a better solution for correcting the problem with showing local time correctly within 2 hours after midnight, works only if the offset is -2 (as for Sweden)
    if (hours in [0, 1]) {  
      newDate.setDate(newDate.getDate()+ 1);
    }

    newDate.setHours(hours - offset);

    return newDate;
  }
}
