import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TestRunService } from 'src/services/test-run.service';
import { TestRunViewModel } from 'src/models/testRun';
import { switchMap, take, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { TestResultsService } from 'src/services/test-results.service';
import { NgControlStatus } from '@angular/forms';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { GetTestRunAction } from 'src/services/Actions/TestRunAction';
import { TmtLoggerService } from 'tmt-logger';

interface TreeNode {
  id: string;
  name: string;
  children?: TreeNode[];
  className: string;
  url?: string;
}

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  className: string;
  url: string;
}
@Component({
  selector: 'app-tree-menu',
  templateUrl: './tree-menu.component.html',
  styleUrls: ['./tree-menu.component.css']
})
export class TreeMenuComponent implements OnInit, OnDestroy {
  testRunViewModel: TestRunViewModel;
  subscription: Subscription;
  private _transformer = (node: TreeNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      className: node.className,
      url: node.url
    };
  };
  activeNode: TreeNode;

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  treeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  readonly inconclusiveIcon: string = "glyphicon glyphicon-question-sign text-warning"
  readonly successIcon: string = "glyphicon glyphicon-ok-sign text-success";
  readonly failureIcon: string = "glyphicon glyphicon-remove-sign text-danger";
  readonly missingIcon: string = "glyphicon glyphicon-minus";

  constructor(
    private testRunService: TestRunService,
    private testRunActionService: GetTestRunAction,
    private loggerService: TmtLoggerService,
    private router: Router) { }

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  @ViewChild('tree') tree;

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    //needed to refresh page so menu items does not render at every click
    if (!localStorage.getItem('treeMenuReload')) {
      localStorage.setItem('treeMenuReload', 'no reload')
      location.reload()
    } else {
      localStorage.removeItem('treeMenuReload')
    }

    this.testRunService.selectedRunUid.subscribe(rid => this.testRunActionService.getTestRun(rid));
    this.testRunActionService.testrunData.subscribe((tr) => {
      this.testRunViewModel = tr;
      this.buildTree(tr);
    },
      error => this.loggerService.logError(error)
    );
  }

  buildTree(runWithSuite: TestRunViewModel) {
    var TSTreeNodes: TreeNode[] = [];
    if (runWithSuite === undefined) {
      return;
    }
    if (runWithSuite?.TestRun?.TestResults?.length > 0) {

      var key = "TestSuiteUid";
      var uniqueTS = [...new Map(runWithSuite?.TestRun?.TestResults.map(item =>
        [item[key], item])).values()];

      uniqueTS.forEach((ts) => {
        if (runWithSuite?.TestRunType === 410)  {
          var TSTreeNode: TreeNode = { id: ts.TestSpecificationUid, name: ts.TestSpecificationName, className: "", children: [] }
          TSTreeNodes.push(TSTreeNode);
        } else {
          var TSTreeNode: TreeNode = { id: ts.TestSuiteUid, name: ts.TestSuiteName, className: "", children: [] }
          TSTreeNodes.push(TSTreeNode);
        }
      });

      runWithSuite.TestRun.TestResults.forEach((tr) => {
        var result: string = tr.Result;
        var treeNodeTestCase: TreeNode = {
          id: tr.TestCaseUid, name: tr.TestCaseName,
          className: this.getIconBasedOnResult(result), url: `/#/testresult/${tr.TestResultUid}`
        }
        if (runWithSuite?.TestRunType === 410)  {
          var foundTestSpecification = TSTreeNodes.filter(x => x.id == tr.TestSpecificationUid);
          foundTestSpecification[0].children.push(treeNodeTestCase);
        } else {
          var foundTS = TSTreeNodes.filter(x => x.id == tr.TestSuiteUid);
          foundTS[0].children.push(treeNodeTestCase);
        }
      })

    }
    this.treeDataSource.data = TSTreeNodes;
    this.tree.treeControl.nodes = TSTreeNodes;
    this.tree.treeControl.expandAll();

    // this.tree.treeControl.nodes.forEach(tsNode => {
    //   tsNode.children.forEach(tcNode => {
    //     if (tcNode.url === this.activeNode?.url) {
    //       console.log("TCNode: ", tcNode);
    //     }
    //   });      
    // });
    // console.log("Active node: ", this.activeNode);   
  }

  setActiveNode(nod: TreeNode) {
    this.activeNode = nod;
  }

  getIconBasedOnResult(resultsForNode: string): string {
    if (resultsForNode == "passed") {
      return this.successIcon;
    }
    else if (resultsForNode == "failed") {
      return this.failureIcon;
    }
    else {
      return this.missingIcon;
    }
  }

  ngOnDestroy(): void {
  }

}
