<c-main-content>
  <primary-items>
    <div class="col-md-2">
      <app-tree-menu></app-tree-menu>
    </div>
    <div class="col-md-10">
      <router-outlet></router-outlet>
    </div>
  </primary-items>
</c-main-content>
