import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { OFFBOARD_COMPONENTS_QUERY } from "src/graphql/queries/abstraction-layer-queries";
import { OffboardComponentWithFile } from "src/models/offboardComponent";
import { ApolloService } from "./apollo.service";
import { OffboardComponentBaseService } from "./offboard-component.base.service";


@Injectable({ providedIn: 'root' })
export class OffboardComponentGraphQLService extends OffboardComponentBaseService {
  constructor(private apolloService: ApolloService) { super(); }

  getOffboardComponents(runUid: string): Observable<OffboardComponentWithFile[]> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsOffboardComponentsForTestRun: OffboardComponentWithFile[] }>({
          query: OFFBOARD_COMPONENTS_QUERY,
          variables: {TestRunUid: runUid}
        })
      }),
      map((result) => {
        if (result.data && result.data.resultsOffboardComponentsForTestRun) {
          return result.data.resultsOffboardComponentsForTestRun
        }
        else {
          return <OffboardComponentWithFile[]>[]
        }
      }),
      shareReplay(1)
    );
  }
}
