

<div *ngIf="requirementsByTestCase?.length > 0">
      <div  class=" no-padding col-md-12">
        <!-- <span class="col-md-3 results-filter-empty"></span> -->
        <span class="checkbox-show-history">
          <fieldset class="results-filter-fieldset">
            <label>{{labelShowAll}}&nbsp;
                <input class="checkbox" type="checkbox" (change)="showHistory($event.target.checked)" />
            </label>      
          </fieldset>
        </span>
      </div>
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container matColumnDef="Requirement.RequirementID">
          <th mat-header-cell *matHeaderCellDef > Requirement ID </th>   
          <td mat-cell *matCellDef="let element">
          <a href="{{requirementsurl}}workitem?id={{element.Requirement.RequirementID}}&revision={{element.Requirement.Version}}" target="_blank">
            <u><span class="scania-link" title="Link to Polarion for this requirement and version"><b>{{element.Requirement.RequirementID}}</b></span></u></a></td>
        </ng-container>
        <ng-container matColumnDef="Requirement.Version" >
          <th mat-header-cell *matHeaderCellDef > IRMA version </th>
          <td mat-cell *matCellDef="let element"><a href="{{requirementsurl}}workitem?id={{element.Requirement.RequirementID}}&revision={{element.Requirement.Version}}" target="_blank">
            <u><span class="scania-link" title="Link to Polarion for this requirement and version"><b>{{element.Requirement.IrmaVersion}}</b></span></u></a></td>
        </ng-container>
        <ng-container matColumnDef="Requirement.Name"  >
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="2"> Requirement name </th>
          <td mat-cell *matCellDef="let element" [attr.colspan]="2">{{element.Requirement.Name}} </td>
        </ng-container>
        <ng-container  >
          <th mat-header-cell *matHeaderCellDef >  </th>
          <td mat-cell *matCellDef="let element"> </td>
        </ng-container>
        <ng-container matColumnDef="Requirement.ASIL" >
            <th mat-header-cell *matHeaderCellDef > ASIL </th>
            <td mat-cell *matCellDef="let element"> {{element.Requirement.ASIL}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'resultRequirement':row.IsResultRequirement === true}"></tr>
        <ng-container matColumnDef="TestAnalysis.Name"  >
            <th mat-header-cell *matHeaderCellDef class="padding-ta">Test analysis name </th>
            <td mat-cell *matCellDef="let element"  class="padding-ta">{{element.TestAnalysis.Name}} </td>
        </ng-container>
        <ng-container matColumnDef="TestAnalysis.Version" >
            <th mat-header-cell *matHeaderCellDef> Test analysis version </th>
            <td mat-cell *matCellDef="let element"> {{element.TestAnalysis.Version}} </td>
        </ng-container>
        <ng-container matColumnDef="TestAnalysis.UID" >
            <th mat-header-cell *matHeaderCellDef > Test analysis UID </th>
            <td mat-cell *matCellDef="let element"> {{element.TestAnalysis.UID}}</td>
        </ng-container>
        <ng-container matColumnDef="TestAnalysis.RegTime" >
          <th mat-header-cell *matHeaderCellDef > Test analysis creation time </th>
          <td mat-cell *matCellDef="let element"> {{element.TestAnalysis.RegTime | date:'yyyy-MM-dd HH:mm:ss'}}</td>
      </ng-container>
      <ng-container matColumnDef="TestAnalysis.ValidTo" >
        <th mat-header-cell *matHeaderCellDef > Test analysis valid to </th>
        <td mat-cell *matCellDef="let element"> {{element.TestAnalysis.ValidTo | date:'yyyy-MM-dd HH:mm:ss'}}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsTA"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsTA;" [ngClass]="{'resultRequirement':row.IsResultRequirement === true}"></tr>
      <ng-container matColumnDef="TestAnalysisAlternative.Name" >
        <th mat-header-cell *matHeaderCellDef class="padding-taalt">Test condition name  </th>
        <td class="border-thick padding-taalt" mat-cell *matCellDef="let element" title="{{element.TestAnalysisAlternative.Name}}">{{element.TestAnalysisAlternative.Name}} </td>
      </ng-container> 
      <ng-container matColumnDef="TestAnalysisAlternative.Version" >
          <th mat-header-cell *matHeaderCellDef > Test condition version  </th>
          <td class="border-thick" mat-cell *matCellDef="let element"> {{element.TestAnalysisAlternative.Version}} </td>
      </ng-container> 
      <ng-container matColumnDef="TestAnalysisAlternative.UID" >
          <th mat-header-cell *matHeaderCellDef > Test condition UID  </th>
          <td class="border-thick" mat-cell *matCellDef="let element"> {{element.TestAnalysisAlternative.UID}} </td>
      </ng-container> 
      <ng-container matColumnDef="TestAnalysisAlternative.RegTime" >
        <th mat-header-cell *matHeaderCellDef > Test condition creation time  </th>
        <td class="border-thick" mat-cell *matCellDef="let element"> {{element.TestAnalysisAlternative.RegTime | date:'yyyy-MM-dd HH:mm:ss'}} </td>
      </ng-container> 
      <ng-container matColumnDef="TestAnalysisAlternative.ValidTo" >
        <th mat-header-cell *matHeaderCellDef > Test condition valid to  </th>
        <td class="border-thick" mat-cell *matCellDef="let element"> {{element.TestAnalysisAlternative.ValidTo | date:'yyyy-MM-dd HH:mm:ss'}} </td>
      </ng-container> 
        <tr mat-header-row *matHeaderRowDef="displayedColumnsTAAlt"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsTAAlt" [ngClass]="{'resultRequirement':row.IsResultRequirement === true}"></tr>
      </table>
    </div>

  <div *ngIf="requirementsByTestCase?.length === 0" class="normal-text">There are no connected requirements</div>
  
