<table class="table-condensed">
    @for(fpc of chosenFPCs; track fpc){
    <tr class="table table-condensed">
        @if(fpc.FamilyDescription !== ''){
        <td>{{fpc.FamilyDescription}}</td>
        <td>{{fpc.VariantDescription}}</td>
        }
    </tr>
    }
    <tr>
        <td>VIN number</td>
        <td>{{VINNumber}}</td>
    </tr>
</table>