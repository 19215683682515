import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { ALTERNATIVES_FOR_TEST_SUITE_QUERY, TEST_CASES_FOR_TEST_SUITE_QUERY, TEST_SUITE_DETAILS_QUERY, TEST_SUITE_QUERY, TEST_SUITES_FOR_TEST_SUITE_QUERY } from "src/graphql/queries/abstraction-layer-queries";
import { TestCase } from "src/models/testCase";
import { TestSuite, TestSuiteAlternative } from "src/models/testRun";
import { ApolloService } from "./apollo.service";
import { TestSuiteBaseService } from "./test-suite.base.service";


@Injectable({ providedIn: 'root' })
export class TestSuiteGraphQLService extends TestSuiteBaseService {
  error: any;

  constructor(private apolloService: ApolloService) { super(); }

  getTestSuiteDetails(testSuiteUid: string, testSuiteVersion?: number): Observable<TestSuite> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsTestSuite: TestSuite }>({
          query: TEST_SUITE_DETAILS_QUERY,
          variables: {
            uid: testSuiteUid,
            version: testSuiteVersion
          }
        })
      }),
      map((result) => {
        if (result.data && result.data.resultsTestSuite) {
          return JSON.parse(JSON.stringify(result.data.resultsTestSuite))
        }
        else {
          return new TestSuite;
        }
      }),
      shareReplay(1)
    );
  }



  getLatestTestSuiteDetails(testSuiteUid: string): Observable<TestSuite> {
    return this.getTestSuiteDetails(testSuiteUid)
  }

  getTestCasesForTestSuite(testSuiteUid: string, testSuiteVersion?: number): Observable<Array<TestCase>> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsTestCasesForTestSuite: Array<TestCase> }>({
          query: TEST_CASES_FOR_TEST_SUITE_QUERY,
          variables: {
            uid: testSuiteUid,
            version: testSuiteVersion
          }
        })
      }),
      map((result) => {
        if (result.data && result.data.resultsTestCasesForTestSuite) {
          return result.data.resultsTestCasesForTestSuite
        }
        else {
          return <TestCase[]>[];
        }
      }),
      shareReplay(1)
    );
  }

  getTestCasesForLatestTestSuite(testSuiteUid: string): Observable<Array<TestCase>> {
    return this.getTestCasesForTestSuite(testSuiteUid)
  }

  getAlternativesForTestSuite(testSuiteUid: string, testSuiteVersion?: number): Observable<Array<TestSuiteAlternative>> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsTestSuiteAlternativesForTestSuite: Array<TestSuiteAlternative> }>({
          query: ALTERNATIVES_FOR_TEST_SUITE_QUERY,
          variables: {
            uid: testSuiteUid,
            version: testSuiteVersion
          }
        })
      }),
      map((result) => {
        if (result.data && result.data.resultsTestSuiteAlternativesForTestSuite) {
          return JSON.parse(JSON.stringify(result.data.resultsTestSuiteAlternativesForTestSuite))
        }
        else {
          return <TestSuiteAlternative[]>[];
        }
      }),
      shareReplay(1)
    );
  }
  getAlternativesForLatestTestSuite(testSuiteUid: string): Observable<Array<TestSuiteAlternative>> {
    return this.getAlternativesForTestSuite(testSuiteUid)
  }
  getTestSuite(uid: string, testSuiteVersion?: number): Observable<TestSuite> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsTestSuite: TestSuite }>({
          query: TEST_SUITE_QUERY,
          variables: {
            uid: uid,
            version: testSuiteVersion
          }
        })
      }),
      map((result) => {
        if (result.data && result.data.resultsTestSuite) {
          return JSON.parse(JSON.stringify(result.data.resultsTestSuite))
        }
        else {
          return new TestSuite;
        }
      }),
      shareReplay(1)
    );

  }

  getLatestTestSuite(uid: string): Observable<TestSuite> {
    return this.getTestSuite(uid)
  }
  getTestSuitesForTestSuite(testSuiteUid: string, testSuiteVersion?: number): Observable<Array<TestSuite>> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsTestSuitesForTestSuite: Array<TestSuite> }>({
          query: TEST_SUITES_FOR_TEST_SUITE_QUERY,
          variables: {
            uid: testSuiteUid,
            version: testSuiteVersion
          }
        })
      }),
      map((result) => {
        if (result.data && result.data.resultsTestSuitesForTestSuite) {
          return JSON.parse(JSON.stringify(result.data.resultsTestSuitesForTestSuite))
        }
        else {
          return <TestSuite[]>[];
        }
      }),
      shareReplay(1)
    );
  }
  getTestSuitesForLatestTestSuite(testSuiteUid: string): Observable<Array<TestSuite>> {
    return this.getTestSuitesForTestSuite(testSuiteUid)
  }

}
