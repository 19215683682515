import { Component, Input, OnChanges } from '@angular/core';
import { TestSuite } from 'src/models/testRun';
import { TestSuiteService } from 'src/services/test-suite.service';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { TmtLoggerService } from 'tmt-logger';
import { TestSuiteBaseService } from 'src/services/test-suite.base.service';

@Component({
  selector: 'app-test-suite-details',
  templateUrl: './test-suite-details.component.html',
  styleUrls: ['./test-suite-details.component.css']
})
export class TestSuiteDetailsComponent implements OnChanges {
  testSuite: TestSuite;
  selectedVersion: number;
  @Input() testSuiteUid: string;
  @Input() testSuiteVersion: number;
  offboardComponentValues: string;

  constructor(private testSuiteService: TestSuiteBaseService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private loggerService: TmtLoggerService) { }

  ngOnChanges() {
    if (this.testSuiteVersion !== undefined) {
      this.getDetails(this.testSuiteUid, this.testSuiteVersion);
    }
    else {
      this.getLatestDetails(this.testSuiteUid);
    }
  }

  getDetails(testSuiteUid: string, testSuiteVersion: number) {
    this.testSuiteService.getTestSuiteDetails(testSuiteUid, testSuiteVersion).subscribe(
      (ts) => {
        this.testSuite = ts;
        this.testSuite.DescriptionSafeHtml = this.sanitizer.bypassSecurityTrustHtml(this.testSuite.Description);
        this.offboardComponentValues = this.testSuite?.OffboardComponents?.map(o => o.Value).join(', ');
        this.selectedVersion = ts.Version;
      },
      error => this.loggerService.logError(error)
    );
  }

  getLatestDetails(testSuiteUid: string) {
    this.testSuiteService.getLatestTestSuiteDetails(testSuiteUid).subscribe(
      (ts) => {
        this.testSuite = ts;
        this.offboardComponentValues = this.testSuite?.OffboardComponents?.map(o => o.Value).join(', ');
        this.selectedVersion = ts.Version;
      },
      error => this.loggerService.logError(error)
    );
  }

  getVersion(versionNo: number) {
    if (!versionNo) {
      this.getLatestDetails(this.testSuiteUid);

      const queryParams: Params = { uid: this.testSuiteUid };
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }
    else {
      this.getDetails(this.testSuiteUid, versionNo);

      const queryParams: Params = { uid: this.testSuiteUid, version: versionNo };
      this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: queryParams,
          queryParamsHandling: 'merge', // remove to replace all query params by provided
        });
    }


  }

}
