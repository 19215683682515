import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, shareReplay, switchMap } from "rxjs/operators";
import { TEST_CASE_ALTERNATIVES_QUERY, TEST_CASE_DETAILS_QUERY, TEST_CASE_QUERY, TEST_CASE_STEPS_QUERY } from "src/graphql/queries/abstraction-layer-queries";
import { Step } from "src/models/step";
import { TestCase, TestCaseAlternative } from "src/models/testCase";
import { ApolloService } from "./apollo.service";
import { TestCaseBaseService } from "./test-case.base.service";


@Injectable({ providedIn: 'root' })
export class TestCaseGraphQLService extends TestCaseBaseService {
  constructor(private apolloService: ApolloService) { super(); }

  getTestCaseSteps(testCaseUid: string, testCaseVersion?: number): Observable<Step[]> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {
        return apolloClients['abstractionLayerClient'].query<{ resultsTestCaseSteps: Step[] }>({
          query: TEST_CASE_STEPS_QUERY,
          variables: {
            uid: testCaseUid,
            version: testCaseVersion
          }
        })
      }),
      map(result => {
        if (result.data && result.data.resultsTestCaseSteps) {
          return JSON.parse(JSON.stringify(result.data.resultsTestCaseSteps))

        } else {
          return <Step[]>[];
        }
      }),
      shareReplay(1)
    );
  }
  getAlternativesForTestCase(testCaseUid: string, testCaseVersion?: number): Observable<TestCaseAlternative[]> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsTestCaseAlternatives: TestCaseAlternative[] }>({
          query: TEST_CASE_ALTERNATIVES_QUERY,
          variables: {
            uid: testCaseUid,
            version: testCaseVersion
          }
        })
      }),
      map(result => {
        if (result.data && result.data.resultsTestCaseAlternatives) {
          return JSON.parse(JSON.stringify(result.data.resultsTestCaseAlternatives))
        } else {
          return <TestCaseAlternative[]>[];
        }
      }),
      shareReplay(1)
    );
  }

  getTestCaseDetails(testCaseUid: string, testCaseVersion?: number): Observable<TestCase> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsTestCaseDetails: TestCase }>({
          query: TEST_CASE_DETAILS_QUERY,
          variables: {
            uid: testCaseUid,
            version: testCaseVersion
          }
        })
      }),
      map(result => {
        if (result.data && result.data.resultsTestCaseDetails) {
          return JSON.parse(JSON.stringify(result.data.resultsTestCaseDetails))
        } else {
          return new TestCase()
        }
      }),
      shareReplay(1)
    );
  }

  getTestCase(testCaseUid: string, testCaseVersion?: number): Observable<TestCase> {
    return this.apolloService.apolloClients$.pipe(
      switchMap(apolloClients => {

        return apolloClients['abstractionLayerClient'].query<{ resultsTestCase: TestCase }>({
          query: TEST_CASE_QUERY,
          variables: {
            uid: testCaseUid,
            version: testCaseVersion
          }
        })
      }),
      map(result => {
        if (result.data && result.data.resultsTestCase) {
          return JSON.parse(JSON.stringify(result.data.resultsTestCase))
        } else {
          return new TestCase()
        }
      }),
      shareReplay(1)
    );
  }

  getLatestTestCaseDetails(testCaseUid: string): Observable<TestCase> {
    return this.getTestCaseDetails(testCaseUid)
  }

  getLatestTestCaseSteps(testCaseUid: string): Observable<Array<Step>> {
    return this.getTestCaseSteps(testCaseUid)
  }

  getAlternativesForLatestTestCase(testCaseUid: string): Observable<Array<TestCaseAlternative>> {
    return this.getAlternativesForTestCase(testCaseUid)
  }

  getLatestTestCase(testCaseUid: string): Observable<TestCase> {
    return this.getTestCase(testCaseUid)
  }
}
