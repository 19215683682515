<div class="col-md-12">
  <br />
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Test runs
        @if (getTestRunByDateRunning) {
          <div class="col-md-12" [ngClass]="{'loader' : getTestRunByDateRunning}"></div>
        }
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div id="divTestResultsByDate" class="col-md-12">
      <div id="divTestResultsByDateHeading" class="col-md-10 no-padding">
        <h2>Test runs {{StartDate | date: 'yyyy-MM-dd'}} - {{EndDate | date: 'yyyy-MM-dd'}}  
          <span *ngIf="PropertyName && PropertyValue">Property name: {{PropertyName}} with property value: {{PropertyValue}} 
            <span class="scania-icon-info xs" style="padding-bottom: 30px;" title="Direct search is done through web search bar so the filters above are not used."></span>
          </span> 
        </h2>
      </div>
      <div id="divTestResultsByDateFilter" class="col-md-2 align-right no-padding">
        <input autofocus type="text" id="txtSearchTestResultsByDate" class="form-control" placeholder="Search in filtered data..."
          (keyup)="applyFilter($event)" />
      </div>

      <div class="mat-elevation-z0">
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="TestSpecification.UID">
            <th mat-header-cell *matHeaderCellDef [hidden]="true"> </th>
            <td mat-cell *matCellDef="let element">{{element.TestSpecification?.UID}}</td>
          </ng-container>
          <ng-container matColumnDef="TestSpecification.Name">
            <th mat-header-cell *matHeaderCellDef [hidden]="true"> </th>
            <td mat-cell *matCellDef="let element">{{element.TestSpecification?.Name}}</td>
          </ng-container>
          <ng-container matColumnDef="TestSuite.TestSuiteUid">
            <th mat-header-cell *matHeaderCellDef [hidden]="true"> </th>
            <td mat-cell *matCellDef="let element">{{element.TestSuite?.TestSuiteUid}}</td>
          </ng-container>
          <ng-container matColumnDef="TestSuite.Name">
            <th mat-header-cell *matHeaderCellDef [hidden]="true"> </th>
            <td mat-cell *matCellDef="let element">{{element.TestSuite?.Name}}</td>
          </ng-container>     
          <ng-container matColumnDef="ItemNo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ItemNo</th>
             <td mat-cell *matCellDef="let element" >
              <span *ngIf="element.TestRunType === 410; else elseBlockItemNo">
                          {{element.TestSpecification?.ItemNo}}
              </span>
              <ng-template #elseBlockItemNo>
                  -
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name (version)</th>
            <td mat-cell *matCellDef="let element" >
              <span *ngIf="element.TestRunType === 410; else elseBlockName">
                          {{element.TestSpecification?.Name}} ({{element.TestSpecification?.Version}})
              </span>
              <ng-template #elseBlockName>
                  {{element.TestSuite?.Name}} ({{element.TestSuite?.Version}})
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="Type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type</th>
            <td mat-cell *matCellDef="let element">
              <span *ngIf="element.TestRunType === 410; else elseBlockType">
                          Test specification
              </span>
              <ng-template #elseBlockType>
                  Test suite
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="TestRun.Environment">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Environment</th>
            <td mat-cell *matCellDef="let element">{{element.TestRun?.Environment}}</td>
          </ng-container>
          <ng-container matColumnDef="TestRun.ExecutionTime">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Execution time</th>
            <td mat-cell *matCellDef="let element">{{element.TestRun?.ExecutionTime | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
          </ng-container>
          <ng-container matColumnDef="TestRun.RegBy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Executed by</th>
            <td mat-cell *matCellDef="let element">{{element.TestRun?.RegBy}}</td>
          </ng-container>   
          <ng-container matColumnDef="TestRun.TestRunUid">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Test run UID</th>
            <td mat-cell *matCellDef="let element"> {{element.TestRun?.TestRunUid}} </td>
          </ng-container>     
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
              [ngClass]="{hovered: row.hovered}" (mouseover)="row.hovered = true" (mouseout)="row.hovered = false"
              (click)="navigateTo(row)">
          </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, filteredTable.length]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </mat-expansion-panel>
</div>
