<div class="row left-margin">
  <div class="col-md-12">
    <app-filter></app-filter>
  </div>
  <app-start-page-table
  [StartDate]="startDate"
  [EndDate]="endDate"
  [PropertyName]="propertyName"
  [PropertyValue]="propertyValue"
  [filteredTable]="filteredTable"></app-start-page-table>
</div>

