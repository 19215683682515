import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TestRun, TestRunViewModel } from 'src/models/testRun';
import { TestResultsTableService } from './services/test-results-table-service';
import { TestRunService } from './../../services/test-run.service';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.css']
})
export class StartPageComponent implements OnInit, OnDestroy {

  subscribe: Subscription;
  filteredTable: TestRunViewModel[];
  startDate: string;
  endDate: string;
  params: Params;
  testCaseUidFromUrl: string;
  propertyName: string;
  propertyValue: string;
  
  constructor(public testResultsTableService: TestResultsTableService,
              public activatedRoute: ActivatedRoute,
              private router: Router,
              public testRunService: TestRunService
              ) { 
  // force route reload whenever params change
  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
 
  }

  ngOnDestroy(): void {
    if (this.subscribe !== undefined)
      this.subscribe.unsubscribe();
  }

  ngOnInit(): void {
    this.filteredTable = [];
    if (this.router.url.includes('testcase/')) {
        this.testCaseUidFromUrl = this.router.url.split('/').pop();
        {
          this.router.navigate(['/testcase/' + this.testCaseUidFromUrl]);
        }
    }
    //show test runs on start page by property in querystring 
    //example: {baseUrl}testrun/property?name=VersionFileCommitHash&value=706fa8f3
    else if (this.router.url.includes('testrun/property')) {
      this.getPropertyNameFromUrlParameter();
      this.getPropertyValueFromUrlParameter();
      this.subscribe = this.testRunService.getTestRunsByProperty(this.getParameterString()).subscribe(propertyTable => {
        this.filteredTable = propertyTable;
      });    
    }
     else {  
      this.subscribe = this.testResultsTableService.testrunsTable.subscribe(rt => { 
        this.filteredTable = rt;
        const dateFilters = this.testResultsTableService.filterSettings;
        if (dateFilters?.startDate && dateFilters?.endDate) {
          this.startDate = dateFilters.startDate;
          this.endDate = dateFilters.endDate;
          this.testResultsTableService.isLoadingTable.next(false);
        }
      });
    }
  }
  
  private getPropertyNameFromUrlParameter()
  {
    this.activatedRoute.queryParams.subscribe(params => {
      this.propertyName = params['name']; 
    });
  }

  private getPropertyValueFromUrlParameter()
  {
    this.activatedRoute.queryParams.subscribe(params => {
      this.propertyValue = params['value'];
    });
  }

  private getParameterString()
  {
    var parameters = "";

    this.activatedRoute.queryParams.subscribe(params => {
        if (this.propertyName && this.propertyValue) {
          parameters += `name=${this.propertyName}&`; 
          parameters += `value=${this.propertyValue}`; 
        }    
    });     

    if (parameters.length > 0)
    {
      parameters = "property?" + parameters;
    }
    return parameters;
  }


}
