<div class="col-md-12 no-padding">
    <div class="col-md-10 no-padding"></div> 
    <div class="col-md-2 align-right no-padding">
      <div class="col-md-12 no-padding">
        <input autofocus type="text" id="txtSearchTestRunProperties" class="form-control" placeholder="Filter..."
          (keyup)="applyFilter($event)" />
      </div>
    </div>
    <div>
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header width="20%"> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
        </ng-container>
        <ng-container matColumnDef="Value">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Value </th>
          <td mat-cell *matCellDef="let element">
            <span *ngIf="element.FieldFormat === 'url'"> <a href='{{element.Value}}' target='_blank'>{{element.Value}}</a></span>
            <span *ngIf="element.FieldFormat !== 'url'">{{element.Value}}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="Description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
          <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
  
      <mat-paginator [pageSizeOptions]="getPageSizeOptions()" showFirstLastButtons></mat-paginator>
    </div>
  </div>

