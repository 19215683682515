import { Component, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TestSpecification } from 'src/models/testSpecification';
import { TestSpecificationBaseService } from 'src/services/test-specification.base.service';
import { TestSpecificationService } from 'src/services/test-specification.service';

@Component({
  selector: 'app-test-specification',
  templateUrl: './test-specification.component.html',
  styleUrls: ['./test-specification.component.css']
})
export class TestSpecificationComponent implements OnInit {
  private subscription: Subscription;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  queryParamUid: string;
  queryParamVersion: string;
  testSpecification: TestSpecification;
  gettingTestSpecification: boolean;
  testSpecificationExist: boolean;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private testSpecificationBaseService: TestSpecificationBaseService) { }

    
  ngOnInit(): void {
    if (this.router.url.includes('testspecificationdetails')) {
      this.getParamUidFromUrlParameter();
      this.getParamVersionFromUrlParameter(); 
      this.getTestSpecification();
    } 
  }

  getTestSpecification(): void {
    this.gettingTestSpecification = true;
    this.testSpecificationExist = true;
    if (this.queryParamVersion !== undefined) {
      this.subscription = this.testSpecificationBaseService.getTestSpecification(this.queryParamUid, +this.queryParamVersion).subscribe(ts => {
        if (ts.UID === undefined || ts.UID === null) {this.testSpecificationExist = false;}
        this.testSpecification = ts;
        this.gettingTestSpecification = false;
      })
    }
    else {
      this.subscription = this.testSpecificationBaseService.getLatestTestSpecification(this.queryParamUid).subscribe(ts => {
        if (ts.UID === undefined || ts.UID === null) {this.testSpecificationExist = false;}
        this.testSpecification = ts;
        this.gettingTestSpecification = false;
      })
    }
  }

  private getParamUidFromUrlParameter()
  {
    this.route.queryParams.subscribe(params => {
      this.queryParamUid = params['uid']; 
    });
  }

  private getParamVersionFromUrlParameter()
  {
    this.route.queryParams.subscribe(params => {
      this.queryParamVersion = params['version'];
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }


}
