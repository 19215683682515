import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base-api.service';
import { RequirementWithTestAnalysis } from 'src/models/requirement';

@Injectable({ providedIn: 'root'})

export abstract class RequirementBaseService extends BaseAPIService{
  public abstract getRequirementsByTestCase(testCaseUid: string, testCaseVersion: number): Observable<RequirementWithTestAnalysis[]>
  public abstract getRequirementsByResult(testResultUid: string): Observable<RequirementWithTestAnalysis[]>
}
