import { TestRunViewModel } from "src/models/testRun";
import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable, Optional } from "@angular/core";
import { TestRunBaseService } from "../test-run.base.service";


@Injectable({
    providedIn: 'root'
})
export class GetTestRunAction {
    private testRunUid: string;
    
    //note: this may be undefined so if using this always perform a if check if its undefined!
    private testRun = new Subject<TestRunViewModel>;
    testrunData = this.testRun.asObservable();
        
    constructor(private testRunBaseService: TestRunBaseService){}

    public getTestRun(uid: string) { 
        if(uid !== this.testRunUid) {
            this.testRunUid = uid;
            this.testRunBaseService.getTestRun(uid).subscribe(k => this.testRun.next(k));
        }
    }
}