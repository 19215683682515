import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TestResult } from '../../../../models/testResult';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { TestRunService } from 'src/services/test-run.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatAccordion } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject, Subscription } from 'rxjs';
import { Constants } from '../../../../constants';
import { HistoryComponent } from '../history/history.component';
import { DialogComponent } from '../dialog/dialog.component';
import { FPC } from 'src/models/sops';
import { environment } from 'src/environments/environment';
import { Classification, ClassificationReason } from 'src/enums/classification';
import { TmtLoggerService } from 'tmt-logger';
import { TestResultsBaseService } from 'src/services/test-results.base.service';
import { TargetEnvironment } from 'src/environments/environment.interfaces';
import { FileService } from 'src/services/file.service';


@Component({
  selector: 'app-test-results',
  templateUrl: './test-results.component.html',
  styleUrls: ['./test-results.component.css'],
  providers: [{provide: FileService}]
})
export class TestResultsComponent implements OnInit, OnDestroy {
  res: TestResult;
  dataSource;
  troubleReportsExist: boolean;
  private subsciption: Subscription;
  displayedColumns = [];
  // TODO start showing Clasification and Classification comment when we have data
  // displayedColumns = ['Execution time', 'Vehicle name', 'Executed by', 'Result', 'TroubleReports',
  //                     'Result comment', 'Classification', 'Classification comment'];
  @ViewChild(MatAccordion) accordion: MatAccordion;
  inputTypePassFail = Constants.InputTypePassFail;
  // TODO Classification is not yet saved on test result or in history table
  parentTypeID = 92; // TestResult
  fieldName = 'Classification';
  totalTestCaseAlternatives: number;
  totalRequirements: number;
  totalEcuReadouts: number;
  totalEcuParams: number;
  totalTroubleReports: number;
  totalResultProperties: number = 0;
  totalRunProperties: number = 0;
  totalProperties: number = 0;
  totalSOPS: number;
  totalOffboardComponents: number;
  responseReadoutNotUploadedByUser: boolean;
  originEcuReadout: string;
  fileTimeEcuReadout: Date;
  ecusInReadout: string[];
  originSOPS: string;
  fileTimeSOPS: Date;
  versionSOPS: number;
  FPCsSOPS: FPC[];
  totalAttachments: number;
  reportsurl = environment.reportsurl;
  resultExist: boolean = true;
  objectUnderTestExist: boolean = false;
  tcAlternativeFailedReasonsExist: boolean = false;
  classificationDescription: string;
  classificationReasonDescription: string;
  environment = environment.environment
  TargetEnvironment = TargetEnvironment

  /**
   * Subject takes test result as input. Observable converts input to file information about current test result.
   */
  private resultSubject: ReplaySubject<TestResult> = new ReplaySubject(1);
  public files$ = this.resultSubject.asObservable().pipe(
    switchMap(result => this.fileService.attachmentFiles$.pipe(map(files => files.filter(file => file.LinkToTestItemUid === result.LinkUid))))
  );

  constructor(
    private testResultsBaseService: TestResultsBaseService,
    private testRunService: TestRunService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private loggerService: TmtLoggerService,
    private fileService: FileService,
  ) {
    if (this.environment != TargetEnvironment.Hero)
      this.displayedColumns = ['Result', 'TroubleReports', 'ResultComment', 'FailedReasonText', 'FailedBy', 'FailedReasonComment', 'TestCaseAlternativeFailedReasons'];
    else
      this.displayedColumns = ['Result', 'TroubleReports', 'ResultComment', 'FailedReasonText', 'FailedBy', 'FailedReasonComment'];
  }


  ngOnDestroy(): void {
    this.subsciption.unsubscribe();
  }

  ngOnInit() {
    this.resultExist = true;
    this.subsciption = this.route.params.pipe(switchMap(params =>
      this.testResultsBaseService.getTestResult(params.uid)
    )).subscribe(
      (tr) => {
        this.fileService.initFilesInformationForTestRun(tr.TestRunUid);
        this.resultSubject.next(tr);
        this.res = tr;
        this.classificationDescription = Classification[tr.Classification];
        this.classificationReasonDescription = ClassificationReason[tr.ClassificationReason];
        if (!this.res.TestResultUid || this.res.TestResultUid === '00000000-0000-0000-0000-000000000000') {
          this.resultExist = false;
        }
        this.troubleReportsExist = tr.troubleReports.some(x => x.Name !== '');
        this.objectUnderTestExist = tr.ecus.length > 0 || tr.offboardComponents.length > 0;
        this.tcAlternativeFailedReasonsExist = tr.testCaseAlternativeFailedReasons.length > 0;
        this.dataSource = new MatTableDataSource<TestResult>([tr]);
        this.testRunService.selectedRunUid.next(tr.TestRunUid);
      },
      error => this.loggerService.logError(error)
    );
  }

  public showTestCaseAlternatives(count: number): void {
    this.totalTestCaseAlternatives = count;
  }
  public showRequirements(count: number): void {
    this.totalRequirements = count;
  }
  public showSOPSCounts(count: number): void {
    this.totalSOPS = count;
  }
  public showEcuReadoutCounts(count: number): void {
    this.totalEcuReadouts = count;
  }
  public showEcuReadoutOrigin(originText: string): void {
    this.originEcuReadout = originText;
  }
  public showEcuReadoutFileTime(fileTime: Date): void {
    this.fileTimeEcuReadout = fileTime;
  }
  public getEcusInReadout(ecus: string[]): void {
    this.ecusInReadout = ecus;
  }
  public showEcuParamCounts(count: number): void {
    this.totalEcuParams = count;
  }
  public showTroubleReportCounts(count: number): void {
    this.totalTroubleReports = count;
  }
  public showResultPropertiesCount(count: number): void {
    this.totalResultProperties = count;
    this.totalProperties = this.totalResultProperties + this.totalRunProperties;
  }
  public showRunPropertiesCount(count: number): void {
    this.totalRunProperties = count;
    this.totalProperties = this.totalRunProperties + this.totalResultProperties;
  }
  public showSOPSOrigin(originText: string): void {
    this.originSOPS = originText;
  }
  public showSOPSFileTime(fileTime: Date): void {
    this.fileTimeSOPS = fileTime;
  }
  public showSOPSVersion(version: number): void {
    this.versionSOPS = version;
  }
  public showSOPSFPCs(fpcs: FPC[]): void {
    this.FPCsSOPS = fpcs;
  }
  public showAttachmentsCounts(count: number): void {
    this.totalAttachments = count;
  }
  public showOffboardComponentsCount(count: number): void {
    this.totalOffboardComponents = count;
  }


  openDialog(header, text): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '800px',
      data: { header: header, text: text }
    });
  }

  openHistoryDialog(parentUidIn): void {
    const dialogRef = this.dialog.open(HistoryComponent, {
      width: '800px',
      data: { parentUid: parentUidIn, parentTypeID: this.parentTypeID, fieldName: this.fieldName }
    });
  }
}
