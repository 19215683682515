<div class="col-md-12 no-padding">
  <div class="col-md-10 no-padding">
    <h4>ECU identification readout through XCOM for test run</h4>
  </div>

  <div class="col-md-2 align-right no-padding">
    <div class="col-md-12 no-padding">
      <input autofocus type="text" id="txtSearchReadout" class="form-control" placeholder="Filter..."
        (keyup)="applyFilter($event)" />
    </div>
  </div>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="Ecu">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ECU </th>
        <td mat-cell *matCellDef="let element"> {{element.Ecu}} </td>
      </ng-container>
      <ng-container matColumnDef="ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.Id}} </td>
      </ng-container>
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
        <td mat-cell *matCellDef="let element"> {{element.Description}} </td>
      </ng-container>
      <ng-container matColumnDef="Value">
        <th mat-header-cell *matHeaderCellDef> Value </th>
        <td mat-cell *matCellDef="let element"> {{element.Value}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="getPageSizeOptions()" showFirstLastButtons></mat-paginator>
  </div>
</div>
