<div class="row">
    <div id="divTestSpecificationDetails" class="col-md-12">  
        <h4>Details for test specification {{testSpecification?.ItemNo}} {{testSpecification?.Name}}</h4> 
        <table class="table">
            <tbody>
                <tr>
                    <td width="20%">Test specification UID</td>
                    <td class="bold">{{testSpecification?.UID}}</td> 
                </tr>  
                <tr>
                    <td>Version number</td>
                    <td >          
                        <mat-form-field class="dropdown-padding" >   
                            <select matNativeControl [ngModel]="selectedTestSpecificationVersion" (change)="getVersion($event.target.value)">
                                <option *ngFor="let version of testSpecification?.Versions" value={{version.VersionNo}} >
                                <span *ngIf="!version.ValidTo" >&nbsp;{{version.VersionNo}}&nbsp;&nbsp;&nbsp;&nbsp;({{version.RegTime | date:'yyyy-MM-dd HH:mm:ss' }} - {{version.RegBy.toUpperCase()}}) latest </span>
                                <span *ngIf="version.ValidTo">&nbsp;{{version.VersionNo}}&nbsp;&nbsp;&nbsp;&nbsp;({{version.RegTime | date:'yyyy-MM-dd HH:mm:ss'}} - {{version.RegBy.toUpperCase()}})</span>
                                </option>
                            </select>
                        </mat-form-field>
                    </td>   
                </tr>  
                <tr>
                    <td>Creation time for this version</td>
                    <td class="bold">{{testSpecification?.RegTime | date: 'yyyy-MM-dd HH:mm:ss'}}</td> 
                </tr>
                <tr>
                    <td>Owner</td>
                    <td class="bold">{{testSpecification?.Owners?.join(', ')}}</td>
                </tr> 
                <tr>
                    <td>Test analyses</td>
                    <td>
                        <div class="col-md-12 no-margin no-padding" *ngIf="testSpecification?.TestAnalyses?.length > 0 ">
                                <table mat-table [dataSource]="dataSource">
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef  width="10%"> Name </th>
                                    <td mat-cell *matCellDef="let data"> {{data.Name}} </td>
                                </ng-container>
                                <ng-container matColumnDef="Version">
                                    <th mat-header-cell *matHeaderCellDef width="5%"> Version </th>
                                    <td mat-cell *matCellDef="let data"> {{data.Version}} </td>
                                </ng-container>
                                <ng-container matColumnDef="RegTime">
                                    <th mat-header-cell *matHeaderCellDef width="10%"> Creation time  </th>
                                    <td mat-cell *matCellDef="let data"> {{data.RegTime  | date: 'yyyy-MM-dd HH:mm:ss'}} </td>
                                </ng-container>
                                <ng-container matColumnDef="RegBy">
                                    <th mat-header-cell *matHeaderCellDef width="10%"> Created by </th>
                                    <td mat-cell *matCellDef="let data"> {{data.RegBy.toUpperCase()}} </td>
                                </ng-container>
                                <ng-container matColumnDef="UID">
                                    <th mat-header-cell *matHeaderCellDef width="10%"> Test analysis UID </th>
                                    <td mat-cell *matCellDef="let data"> {{data.UID}} </td>
                                    </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedTestAnalysesColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedTestAnalysesColumns;" [ngClass]="{hovered: row.hovered}"
                                (mouseover)="row.hovered = true" (mouseout)="row.hovered = false" ></tr>
                            </table>
                        </div>
                        <div *ngIf="testSpecification?.TestAnalyses?.length === 0"><span class="scania-icon-info xs"></span><span class="bold">There are no test analyses for this test specification</span></div>
                    </td>  
                </tr>
            </tbody>
        </table>   
      
    </div>
</div>
