<div class="row">
    <div id="divTestCaseDetails" class="col-md-12">   
        <h4>Details for test case: {{testCase?.Name}}</h4>
            <table class="table">
                <tbody>
                    <tr *ngIf="tcversionchangeable === false" class="scania-link" [routerLink]="['/testcasedetails']" [queryParams]="{uid: testCase?.TCUID, version: selectedVersion}" title="Show test case details page">
                        <td class="scania-link" width="20%"><u>Test case UID</u></td>
                        <td class="bold scania-link"><u>{{testCase?.TCUID}}</u></td> 
                    </tr>  
                    <tr *ngIf="tcversionchangeable === true" >
                        <td width="20%">Test case UID</td>
                        <td class="bold">{{testCase?.TCUID}}</td> 
                    </tr>  
                    <tr>
                        <td>Version number</td>
                        <td>          
                            <mat-form-field class="dropdown-padding">   
                                <select matNativeControl [ngModel]="selectedVersion" (change)="getVersion($event.target.value)" [disabled]="!tcversionchangeable" >
                                  <option *ngFor="let version of testCase?.Versions" value={{version.VersionNo}} >
                                    <span *ngIf="!version.ValidTo" >&nbsp;{{version.VersionNo}}&nbsp;&nbsp;&nbsp;&nbsp;({{version.RegTime | date:'yyyy-MM-dd HH:mm:ss' }} - {{version.RegBy.toUpperCase()}}) latest </span>
                                    <span *ngIf="version.ValidTo">&nbsp;{{version.VersionNo}}&nbsp;&nbsp;&nbsp;&nbsp;({{version.RegTime | date:'yyyy-MM-dd HH:mm:ss'}} - {{version.RegBy.toUpperCase()}})</span>
                                  </option>
                                </select>
                            </mat-form-field>
                        </td>   
                    </tr>  
                    <tr>
                        <td>Creation time for this version</td>
                        <td class="bold">{{testCase?.RegTime | date: 'yyyy-MM-dd HH:mm:ss'}}</td> 
                    </tr>  
                    <tr>
                        <td>UF</td>
                        <td>
                            <table class="no-margin bold">  
                                <tr *ngFor="let uf of testCase?.UFs">
                                    <td width="70px" class="no-padding">UF{{uf.UFNumber}}</td>
                                    <td class="no-padding">{{uf.Name}}</td>
                                </tr>
                            </table> 
                        </td>
                    </tr>
                    <tr>
                        <td>Product properties</td>
                        <td>
                            <table class="no-margin">  
                                <tr *ngFor="let productProp of testCase?.ProductProperties">
                                    <td width="200px" class="no-padding bold" >{{productProp.Name}}</td> 
                                    <td class="no-padding">Category: {{productProp.ProductCategoryName}}</td>
                                </tr>
                            </table> 
                        </td>
                    </tr>
                    <tr>
                        <td>Owner</td>
                        <td class="bold">{{testCase?.Owners?.join(', ')}}</td> 
                    </tr>
                    <tr>
                        <td>ECUs</td>
                        <td>
                            <table class="no-margin" *ngIf="testCase?.ECUs">  
                                <tr *ngFor="let ecu of testCase?.ECUs" > 
                                    <td class="bold no-padding" width="10%">{{ecu.name}}</td>
                                    <td class="no-padding">{{ecu.description}}</td>
                                    <td class="no-padding">{{ecu.fpcCondition}}</td>
                                    <td class="no-padding">ECUFamily: {{ecu.familyName}}</td>
                                </tr>
                            </table> 
                        </td>
                    </tr>
                    <tr>
                        <td>Offboard components</td>
                        <td class="bold"><span *ngIf="offboardComponentValues?.length > 0">{{offboardComponentValues}}</span></td>
                    </tr>
                    <tr>
                        <td style="vertical-align: top;">Description</td>
                        <td><div [innerHTML]="testCase?.DescriptionSafeHtml"></div></td>
                    </tr> 
                                       
                    <!-- <tr>
                        <td>Script</td>
                        <td>{{testCase?.Script}}</td> 
                    </tr>                   
                    <tr>
                        <td>Requirement</td>
                        <td>{{testCase?.Requirement}}</td> 
                    </tr>  
                    <tr>
                        <td>Result count</td>
                        <td>{{testCase?.ResultCount}}</td> 
                    </tr> 
                    <tr>
                        <td>Input type</td>
                        <td>{{testCase?.InputTypeId}}</td> 
                    </tr> 
                    <tr>
                        <td>Input type</td>
                        <td>{{testCase?.InputTypeId}}</td> 
                    </tr>  -->   
                </tbody>
            </table>
    </div>    
</div>
