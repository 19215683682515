import { Observable } from "rxjs";
import { BaseAPIService } from "./base-api.service";
import { Injectable } from "@angular/core";
import { TestResult } from "src/models/testResult";
import { Property } from "src/models/property";

@Injectable({ providedIn: 'root' })
export abstract class TestResultsBaseService extends BaseAPIService {
    public abstract getTestResult(uid: string): Observable<TestResult>
    public abstract getTestResultProperties(uid: string): Observable<Property[]>
}
