@if(environment !== TargetEnvironment.Hero){
  @if(images$ | async; as images) {

    <div *ngIf="images?.length > 0">
      <div>
        <h4 style="display: inline;">Attached images</h4>
        <span> (click to enlarge)</span>
      </div>
      <div style="clear:both"></div>
      <p-galleria [value]="images" [(visible)]="displayCustom" [(activeIndex)]="activeIndex"
      [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '850px'}" [numVisible]="2"
      [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false"
      [baseZIndex]="100000">
      <ng-template pTemplate="item" let-item>
        <img [src]="item?.itemImageSrc" style="max-height: 900px; width:auto; display: block;" />
      </ng-template>
      <ng-template pTemplate="thumbnail" let-item>
        <div class="p-grid p-nogutter p-justify-center">
          <img [src]="item?.itemImageSrc" style="display: block;" />
        </div>
      </ng-template>
    </p-galleria>

    <div class="p-grid container">
      <div *ngFor="let image of images; let index = index;" class="p-col-3" key="index">
        <img [src]="image?.itemImageSrc" class="image-thumbnail" title="{{image.Name}}"
        (click)="imageClick(index)" />
      </div>
    </div>
  </div>
  }
}
